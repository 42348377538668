import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = {
  key: 1,
  class: "bulk-operation-listing-header"
}
const _hoisted_3 = { class: "inline-flex align-items-center" }
const _hoisted_4 = { class: "width100" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "inline-flex" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "description" }
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  key: 0,
  class: "radio-button-content"
}
const _hoisted_11 = { class: "description" }
const _hoisted_12 = ["checked"]
const _hoisted_13 = {
  key: 3,
  class: ""
}
const _hoisted_14 = { class: "inline-flex description align-items-center" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "margin-left-5" }
const _hoisted_17 = {
  key: 4,
  class: "text-center"
}
const _hoisted_18 = ["index"]
const _hoisted_19 = {
  key: 0,
  class: "dateSeparator"
}
const _hoisted_20 = { class: "inline-flex100" }
const _hoisted_21 = { class: "inline-flex" }
const _hoisted_22 = { class: "select-program-checkbox margin-left-15" }
const _hoisted_23 = ["id", "value", "disabled", "onClick"]
const _hoisted_24 = { class: "time-programtitle description" }
const _hoisted_25 = { class: "time" }
const _hoisted_26 = { class: "margin-left-5" }
const _hoisted_27 = { class: "programTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('AddToPrograms'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AssignProgramsToSelectedSessionQuestionsConfirmed($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.isBulkEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
            }, _toDisplayString(_ctx.$t("CancelButton")), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("SessionQuestions.AddToFollowingProgramItems")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              disabled: _ctx.programItems.length === 0,
              class: "btn btn-primary btn-size-default",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.AssignProgramsToSelectedSessionQuestions()))
            }, _toDisplayString(_ctx.$t("SaveButton")), 9, _hoisted_7)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.programItems.length>0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass({ 'inline-flex': !_ctx.isBulkEdit})
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'radio-margin-bulk': _ctx.isBulkEdit})
          }, [
            _createElementVNode("label", _hoisted_8, [
              _createElementVNode("input", {
                type: "radio",
                class: "radio-button",
                name: "selectedprograms",
                checked: _ctx.selectAllRadioButtonOption,
                value: "all",
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SelectAllProgramItems()))
              }, null, 40, _hoisted_9),
              _createElementVNode("span", {
                class: _normalizeClass(["font-weight-bold", {'batch-label-color': _ctx.isBulkEdit}])
              }, _toDisplayString(_ctx.$t("SessionQuestions.ApplyToAll")), 3)
            ]),
            (_ctx.isBulkEdit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("SessionQuestions.ApplyToAllProgramsSubTitle")), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({ 'radio-margin-bulk': _ctx.isBulkEdit,'radio-margin':!_ctx.isBulkEdit})
          }, [
            _createElementVNode("label", _hoisted_11, [
              _createElementVNode("input", {
                type: "radio",
                name: "selectedprograms",
                class: "radio-button",
                value: "selected",
                checked: !_ctx.selectAllRadioButtonOption,
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.DeSelectAllProgramItems()))
              }, null, 40, _hoisted_12),
              _createElementVNode("span", {
                class: _normalizeClass(["font-weight-bold", {'batch-label-color-selected': _ctx.isBulkEdit}])
              }, _toDisplayString(_ctx.$t("SessionQuestions.ApplyToSelected")), 3)
            ])
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.programItems.length>0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("label", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectAllProgramItems) = $event)),
              type: "checkbox",
              class: "largerCheckbox",
              disabled: _ctx.disableAllProgramItems
            }, null, 8, _hoisted_15), [
              [_vModelCheckbox, _ctx.selectAllProgramItems]
            ]),
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("SelectAll")), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("Program.NoProgramExists")), 1)
        ])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programItems, (programItem, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: programItem.Id,
        index: index
      }, [
        (_ctx.drawDate(index))
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("label", null, _toDisplayString(_ctx.formatDate(programItem)), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", _hoisted_22, [
              _withDirectives(_createElementVNode("input", {
                id: 'pi_' + programItem.Id,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedProgramItems) = $event)),
                type: "checkbox",
                class: "largerCheckbox",
                value: programItem,
                disabled: _ctx.disableAllProgramItems,
                onClick: ($event: any) => (_ctx.checkProgramItems(programItem,index,$event))
              }, null, 8, _hoisted_23), [
                [_vModelCheckbox, _ctx.selectedProgramItems]
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.formatTime(programItem)) + " -", 1),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, _toDisplayString(programItem.Title), 1)
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
              ])
            ])
          ])
        ])
      ], 8, _hoisted_18))
    }), 128))
  ]))
}