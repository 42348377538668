import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "batch-operation" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "main-heading-parent" }
const _hoisted_4 = { class: "bulk-action-text-margin" }
const _hoisted_5 = { class: "batch-operation-main-content" }
const _hoisted_6 = { class: "action-middle-div" }
const _hoisted_7 = ["index"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "img-select iconSize" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "selectInformationStyle valign-parent60 item-position-from-image" }
const _hoisted_12 = { class: "valign batch-content-width" }
const _hoisted_13 = ["id"]
const _hoisted_14 = {
  key: 0,
  class: "action-middle-width"
}
const _hoisted_15 = { class: "component_section right-container-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_EvaluationQuestionListing = _resolveComponent("EvaluationQuestionListing")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (!_ctx.showEvaluationQuestionListing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t("Program.EvaluationQuestions.Batch.Header1")) + " " + _toDisplayString(_ctx.selectedItems.length) + " " + _toDisplayString(_ctx.$t("Program.Batch.Header2")), 1)
          ]),
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("Bulk.SelectAction")), 1),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "action-side-width" }, null, -1)),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localBulkOperations, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.Id,
                  index: index,
                  class: _normalizeClass(["inner-content", _ctx.evenIndex(index)==true?'action-content-width-left':'action-content-width-right'])
                }, [
                  _createElementVNode("div", {
                    class: "inner-content",
                    onClick: ($event: any) => (_ctx.handle_function_call(item.objModel,item.ConfirmationMessage,item.ConfirmationMessageSubTitle,item.ShowConfirmationPopup))
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: item.IconUrl,
                        style: _normalizeStyle(item.IconPadding)
                      }, null, 12, _hoisted_10)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("label", {
                          id: 'EvaluationQuestionsBulkOperation'+index,
                          class: "hyperLink questionLinkFont"
                        }, _toDisplayString(item.Name), 9, _hoisted_13),
                        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                      ])
                    ])
                  ], 8, _hoisted_8),
                  (_ctx.evenIndex(index))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_7))
              }), 128))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "action-side-width" }, null, -1))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      (_ctx.showEvaluationQuestionListing)
        ? (_openBlock(), _createBlock(_component_EvaluationQuestionListing, {
            key: 0,
            "selected-items": _ctx.selectedItems,
            "program-items": _ctx.programItems,
            "is-bulk-operation": true,
            onShowBulkOperation: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowBulkOperation($event)))
          }, null, 8, ["selected-items", "program-items"]))
        : _createCommentVNode("", true)
    ])
  ]))
}