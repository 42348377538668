import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-margin" }
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = { class: "padding-top-20" }
const _hoisted_10 = { class: "margin-top-10" }
const _hoisted_11 = { class: "inline-flex" }
const _hoisted_12 = { class: "padding-3 margin-left-5 description" }
const _hoisted_13 = { class: "margin-top-30" }
const _hoisted_14 = {
  key: 0,
  class: "margin-top-30"
}
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_InputTag = _resolveComponent("InputTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseTags()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ProgramTag.EditTags")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveTags()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.visibleValidationSummary)
            ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                key: 0,
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.overwriteLinkedTags) = $event)),
              type: "checkbox",
              class: "largerCheckbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.overwriteLinkedTags]
            ]),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("ProgramTag.OverwritePreviouslyLinkedTags")), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("ProgramTag.BulkOperationOverwriteDescription")), 1)
        ]),
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("ProgramTag.SelectTags")), 1),
        _createVNode(_component_InputTag, {
          "selected-tags": _ctx.selectedTags,
          "existing-tags": _ctx.existingTags,
          onSelectedTags: _ctx.SetTagNamesValue
        }, null, 8, ["selected-tags", "existing-tags", "onSelectedTags"]),
        (_ctx.previouslyUsedProgramTags.length>0)
          ? (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.$t("ProgramTag.PreviouslyUsedTags")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previouslyUsedProgramTags, (tag) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tag.Id,
              class: "inline-flex padding-3"
            }, [
              _createElementVNode("span", {
                class: "tags-input-badge tags-input-badge-pill tags-input-badge-selected-default choose-tag",
                onClick: ($event: any) => (_ctx.AppendTags(tag))
              }, [
                _createElementVNode("span", null, _toDisplayString(tag.TagName), 1),
                _cache[4] || (_cache[4] = _createElementVNode("i", {
                  class: "fa fa-plus margin-left-5",
                  "aria-hidden": "true"
                }, null, -1))
              ], 8, _hoisted_15)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}