import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "item-listing" }
const _hoisted_2 = {
  key: 1,
  class: "emptyDiv"
}
const _hoisted_3 = { class: "no-item-parent-div" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "no-item-message-container" }
const _hoisted_6 = { class: "no-item-message" }
const _hoisted_7 = {
  key: 2,
  class: "itemsDiv"
}
const _hoisted_8 = ["index", "disabled"]
const _hoisted_9 = { class: "trackItem-parent" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "program-body inline-flex100" }
const _hoisted_12 = {
  key: 0,
  class: "checkbox-container"
}
const _hoisted_13 = ["id", "value", "onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "program-title-parent" }
const _hoisted_17 = { class: "inline-flex100 description-heading" }
const _hoisted_18 = ["title"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "program-body inline-flex100" }
const _hoisted_21 = {
  key: 0,
  class: "checkbox-container"
}
const _hoisted_22 = ["id", "value", "onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "program-title-parent" }
const _hoisted_26 = { class: "inline-flex100" }
const _hoisted_27 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.programItems==null || _ctx.programItems.length==0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createElementVNode("label", {
                class: "no-item-font-weight font-size-30",
                innerHTML: _ctx.$t('Program.LandingPageNoItemMessage')
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t("Program.StartBytext")) + " ", 1),
              _createElementVNode("a", {
                class: "hyperLink questionLinkFont",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenCreateNewQuestionpane()))
              }, _toDisplayString(_ctx.$t("Program.CreatingQuestionLink")), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programItems, (programItem, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: programItem.Id,
              index: index,
              class: _normalizeClass(["program-item-default-bg-color", [programItem.DisableItem ? '' : 'cursor-pointer']]),
              disabled: programItem.DisableItem,
              style: _normalizeStyle([programItem.DisableItem ==true ? { 'pointer-events':'none','opacity':'0.4' } : '',
                 _ctx.isPartOfTrack(index) == false? {'background-color': programItem.styleProgramDiv}:'' ])
            }, [
              (_ctx.drawDate(index))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([index > 0 ? 'margin-top-10' : '', "dateSeparator bold-text"])
                  }, _toDisplayString(_ctx.formatDate(programItem)), 3))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.isPartOfTrack(index))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_ctx.drawTrack(index))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "panel-heading",
                            style: _normalizeStyle([programItem.Track.TrackColor !== null ? {'background': programItem.Track.TrackColor}:{'background': _ctx.GetDefaultColor()}, _ctx.darkColor(programItem.Track.TrackColor) ? {'color': 'black'} : {'color': 'white'}])
                          }, _toDisplayString(programItem.Track ? programItem.Track.Name : ''), 5))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: "trackItem",
                        style: _normalizeStyle([ _ctx.programItems.length !== index + 1 && _ctx.drawDate(index + 1) ? {'border-bottom': '1px solid'} : '', programItem.Track.TrackColor !== null ? {'border-color': programItem.Track.TrackColor}:{'border-color': _ctx.GetDefaultColor()},
              ])
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass([_ctx.drawDate(index + 1 ) ? '' : 'item-saparator', "div-program"]),
                          style: _normalizeStyle({ 'background-color': programItem.styleProgramDiv })
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            (_ctx.isMobileView==false)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: 'pi_' + programItem.Id,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
                                    type: "checkbox",
                                    class: "noItemDisplay",
                                    value: programItem,
                                    onClick: ($event: any) => (_ctx.checkProgramItems(programItem,index,$event))
                                  }, null, 8, _hoisted_13), [
                                    [_vModelCheckbox, _ctx.selectedItems]
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("a", {
                              href: "javascript:void(0)",
                              class: "inline-flex100 ipad_tag",
                              onClick: ($event: any) => (_ctx.selectItem(programItem))
                            }, [
                              _createElementVNode("img", {
                                src: programItem.ImageUrl,
                                class: "program-icon-Img round-image"
                              }, null, 8, _hoisted_15),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("span", {
                                  class: "color-grey description",
                                  style: _normalizeStyle({'color': programItem.styleProgramTimeText})
                                }, _toDisplayString(_ctx.formatTime(programItem)), 5),
                                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("div", {
                                    class: "description-heading",
                                    style: _normalizeStyle({'color': programItem.styleProgramDescriptionText})
                                  }, _toDisplayString(programItem.Title), 5)
                                ]),
                                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
                              ]),
                              (programItem.ProgramTags!=null && programItem.ProgramTags.length>0 && _ctx.isMobileView==false)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    title: _ctx.formatTagsTitle(programItem),
                                    class: "tags"
                                  }, _toDisplayString(_ctx.formatTags(programItem)), 9, _hoisted_18))
                                : _createCommentVNode("", true),
                              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                            ], 8, _hoisted_14)
                          ])
                        ], 6)
                      ], 4),
                      (_ctx.drawFinalTrack(index))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "finalTrack",
                            style: _normalizeStyle([programItem.Track.TrackColor !== null ? {'border-color': programItem.Track.TrackColor}:{'border-color': _ctx.GetDefaultColor()}])
                          }, null, 4))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.drawDate(index + 1 ) ? '' : 'item-saparator', "div-program"])
                      }, [
                        _createElementVNode("div", _hoisted_20, [
                          (_ctx.isMobileView==false)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _withDirectives(_createElementVNode("input", {
                                  id: 'pi_' + programItem.Id,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedItems) = $event)),
                                  type: "checkbox",
                                  class: "noItemDisplay",
                                  value: programItem,
                                  onClick: ($event: any) => (_ctx.checkProgramItems(programItem,index,$event))
                                }, null, 8, _hoisted_22), [
                                  [_vModelCheckbox, _ctx.selectedItems]
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            class: "inline-flex100 ipad_tag",
                            onClick: ($event: any) => (_ctx.selectItem(programItem))
                          }, [
                            _createElementVNode("img", {
                              src: programItem.ImageUrl,
                              class: "program-icon-Img round-image"
                            }, null, 8, _hoisted_24),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("span", {
                                class: "color-grey description",
                                style: _normalizeStyle({'color': programItem.styleProgramTimeText})
                              }, _toDisplayString(_ctx.formatTime(programItem)), 5),
                              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("div", {
                                  class: "description-heading",
                                  style: _normalizeStyle({'color': programItem.styleProgramDescriptionText})
                                }, _toDisplayString(programItem.Title), 5)
                              ]),
                              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
                            ]),
                            (programItem.ProgramTags!=null && programItem.ProgramTags.length>0 && _ctx.isMobileView==false)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  title: _ctx.formatTagsTitle(programItem),
                                  class: "tags"
                                }, _toDisplayString(_ctx.formatTags(programItem)), 9, _hoisted_27))
                              : _createCommentVNode("", true),
                            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
                          ], 8, _hoisted_23)
                        ])
                      ], 2)
                    ]))
              ])
            ], 14, _hoisted_8))
          }), 128))
        ]))
  ]))
}