import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = { class: "top-margin-in-fields inline-flex100 justify-content-space-between" }
const _hoisted_3 = { class: "width55" }
const _hoisted_4 = { class: "new-sub-section-heading margin-top-0" }
const _hoisted_5 = {
  key: 0,
  class: "action-button"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "margin-left-3" }
const _hoisted_8 = {
  key: 0,
  class: "ajax-loader grid-listing"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "max-check-ins-div"
}
const _hoisted_12 = { class: "margin-left-20" }
const _hoisted_13 = ["max", "min"]
const _hoisted_14 = ["value"]
const _hoisted_15 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("SessionCheckIn")), 1)
      ]),
      (_ctx.localSessionCheckInData.IsSessionCheckInEnabled && _ctx.localSessionCheckInData.Id !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.excelIcon,
              class: "excel-icon-size"
            }, null, 8, _hoisted_6),
            _createElementVNode("a", {
              class: "hyperLink",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ExportResultsToExcel && _ctx.ExportResultsToExcel(...args)))
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isDataLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_9)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("label", {
            class: "description margin-bottom-0",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SaveData()))
          }, [
            _withDirectives(_createElementVNode("input", {
              ref: "chkSessionCheckInEnabled",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSessionCheckInData.IsSessionCheckInEnabled) = $event)),
              class: "checkbox-alignment description",
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.localSessionCheckInData.IsSessionCheckInEnabled]
            ]),
            _createTextVNode(_toDisplayString(_ctx.$t("SessionCheckIn.EnableCheckInByScanningApp")), 1)
          ]),
          (_ctx.localSessionCheckInData.IsSessionCheckInEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("label", {
                  class: "max-check-ins description",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ToggleMaxSeats()))
                }, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.areSeatsLimited) = $event)),
                    class: "checkbox-alignment",
                    type: "checkbox"
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.areSeatsLimited]
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.$t("SessionCheckIn.LimitMaximumCheckIns")), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.areSeatsLimited)
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 0,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$.localSessionCheckInData.MaxCheckInLimitQuantity.$model) = $event)),
                        type: "number",
                        class: _normalizeClass(["form-control max-check-in-count", _ctx.status(_ctx.v$.localSessionCheckInData.MaxCheckInLimitQuantity)]),
                        max: _ctx.localSessionCheckInData.MaxParticipantCheckInLimit,
                        min: _ctx.localSessionCheckInData.MinParticipantCheckInLimit,
                        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ValidatePositiveInteger($event))),
                        onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.SaveData()))
                      }, null, 42, _hoisted_13)), [
                        [_vModelText, _ctx.v$.localSessionCheckInData.MaxCheckInLimitQuantity.$model]
                      ])
                    : (_openBlock(), _createElementBlock("input", {
                        key: 1,
                        type: "text",
                        disabled: "",
                        class: "form-control max-check-in-count",
                        value: _ctx.$t('Unlimited')
                      }, null, 8, _hoisted_14)),
                  (_ctx.localSessionCheckInData.Id !== 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('SessionCheckIn.ParticipantsCheckedIn')), 1),
                        _createElementVNode("span", {
                          class: "hyper-link margin-left-5",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.GetCheckedInParticipantDetails()))
                        }, _toDisplayString(_ctx.GetCheckedInParticipantCount()), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}