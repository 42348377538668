import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "sessionQuestionsListDiv",
  class: "item-listing"
}
const _hoisted_2 = {
  key: 1,
  class: "emptyDiv"
}
const _hoisted_3 = { class: "no-item-parent-div" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "no-item-message-container" }
const _hoisted_6 = { class: "no-item-message" }
const _hoisted_7 = {
  key: 2,
  class: "itemsDiv"
}
const _hoisted_8 = {
  key: 0,
  class: "alert alert-success"
}
const _hoisted_9 = {
  key: 1,
  class: "alert alert-success"
}
const _hoisted_10 = ["hidden"]
const _hoisted_11 = ["href", "title"]
const _hoisted_12 = { class: "noItemDisplayForWeb color-blue" }
const _hoisted_13 = { class: "noTabularItemDisplay color-blue" }
const _hoisted_14 = { class: "noItemDisplayForWeb color-blue" }
const _hoisted_15 = { class: "noTabularItemDisplay color-blue" }
const _hoisted_16 = { class: "session-question-link-expire-container" }
const _hoisted_17 = {
  key: 0,
  class: "expire-link-date"
}
const _hoisted_18 = {
  key: 1,
  class: "edit-box-width"
}
const _hoisted_19 = { class: "session-question-datepicker" }
const _hoisted_20 = ["indexSession", "disabled"]
const _hoisted_21 = { class: "checkbox-container" }
const _hoisted_22 = ["id", "value", "onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["src"]
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "question-container" }
const _hoisted_27 = { class: "inline-flex100" }
const _hoisted_28 = { class: "valign-parent50 attached-programs-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_loading = _resolveComponent("loading")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.localSessionQuestions==null || _ctx.localSessionQuestions.length==0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createElementVNode("label", {
                class: "font-size-30",
                innerHTML: _ctx.$t('SessionQuestions.LandingPageNoItemMessage')
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t("SessionQuestions.StartBytext")) + " ", 1),
              _createElementVNode("a", {
                class: "hyperLink questionLinkFont",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenCreateNewQuestionpane()))
              }, _toDisplayString(_ctx.$t("SessionQuestions.CreatingQuestionLink")), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                (_ctx.showCopiedMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[7] || (_cache[7] = _createElementVNode("i", {
                        class: "fa fa-check",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.showSuccessMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[8] || (_cache[8] = _createElementVNode("i", {
                        class: "fa fa-check",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("Message.SuccessfullySavedOrUpdated")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          (_ctx.ProgramQuestionPublishedLink!=null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "toggling-container",
                hidden: _ctx.existingSessionQuestionsCount===0
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["publishLink", { 'publish-link-container': _ctx.editLinkExpirationDate}])
                }, [
                  _createElementVNode("label", null, [
                    _createElementVNode("a", {
                      target: "_blank",
                      class: "hyperLink margin-left-15",
                      href: _ctx.ProgramQuestionPublishedLink,
                      title: _ctx.ProgramQuestionPublishedLink
                    }, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("SessionQuestions.Link.ViewResults-Tab")), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("SessionQuestions.Link.ViewResults")), 1)
                    ], 8, _hoisted_11)
                  ]),
                  _createElementVNode("label", null, [
                    _createElementVNode("a", {
                      class: "hyperLink margin-left-15",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyPublicLinkToClipboard && _ctx.copyPublicLinkToClipboard(...args)))
                    }, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("Program.SessionQuestions.CopyLink-Tab")), 1),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Program.SessionQuestions.CopyLink")), 1)
                    ])
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "display-inlineflex noItemDisplayEditExpireLink",
                    style: _normalizeStyle([_ctx.editLinkExpirationDate == true ? { 'width':'auto' } : {'width':'auto'}])
                  }, [
                    _createElementVNode("label", {
                      class: _normalizeClass(["link-expiration-heading", { 'noTabularItemDisplay': _ctx.editLinkExpirationDate}]),
                      style: _normalizeStyle([_ctx.editLinkExpirationDate == true ? { 'margin-left':'auto' } : {'margin-left':'0%'}])
                    }, _toDisplayString(_ctx.$t("SessionQuestions.LinkExpiration")), 7)
                  ], 4),
                  (!_ctx.editLinkExpirationDate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("a", {
                          class: "hyperLink margin-left-5",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.EditLinkExpirationDate()))
                        }, _toDisplayString(_ctx.formatLinkExpirationDate(_ctx.ProgramQuestionLinkExpirationDate)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.editLinkExpirationDate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          (_ctx.editLinkExpirationDate)
                            ? (_openBlock(), _createBlock(_component_Datepicker, {
                                key: 0,
                                class: "margin-left-5",
                                "date-only": true,
                                date: _ctx.ProgramQuestionLinkExpirationDate,
                                "id-value": _ctx.$t('SessionQuestions.LinkExpiration'),
                                onSelectedDate: _ctx.SetProgramQuestionLinkExpirationDate
                              }, null, 8, ["date", "id-value", "onSelectedDate"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("a", {
                          class: "hyperLink session-expire-date-save",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SaveLinkExpirationDate()))
                        }, _toDisplayString(_ctx.$t("SaveButton")), 1),
                        _createElementVNode("a", {
                          class: "hyperLink session-expire-date-cancel",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CancelLinkExpirationDate()))
                        }, _toDisplayString(_ctx.$t("CancelButton")), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_10))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localSessionQuestions, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.Id,
                class: _normalizeClass(["programDiv program-item-default-bg-color item-saparator", [item.DisableItem ? '' : 'cursor-pointer']]),
                indexSession: index,
                disabled: item.DisableItem,
                style: _normalizeStyle([item.DisableItem ==true ? { 'pointer-events':'none','opacity':'0.4' } : ''])
              }, [
                _createElementVNode("div", {
                  class: "program-body inline-flex100",
                  style: _normalizeStyle({ 'background-color': item.defaultBGColor })
                }, [
                  _createElementVNode("div", _hoisted_21, [
                    _withDirectives(_createElementVNode("input", {
                      id: 'pq_' + item.Id,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedItems) = $event)),
                      type: "checkbox",
                      class: "noItemDisplay",
                      value: item,
                      onClick: ($event: any) => (_ctx.checkSessionQuestions(item,index,$event))
                    }, null, 8, _hoisted_22), [
                      [_vModelCheckbox, _ctx.selectedItems]
                    ])
                  ]),
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    class: "inline-flex100",
                    onClick: ($event: any) => (_ctx.selectSessionQuestion(item))
                  }, [
                    (item.ProgramQuestionType===1)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "program-icon-Img round-image",
                          src: _ctx.textFeedbackIcon
                        }, null, 8, _hoisted_24))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: _ctx.pollingIcon
                        }, null, 8, _hoisted_25)),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", {
                            class: "time description-heading",
                            style: _normalizeStyle({ 'color': item.defaultTextColor })
                          }, _toDisplayString(item.questionTitle), 5)
                        ])
                      ]),
                      (item.ProgramQuestionType===1)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            style: _normalizeStyle({ 'color': item.defaultSubTextColor })
                          }, _toDisplayString(_ctx.$t("SessionQuestions.QuestionType.Text")), 5))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            style: _normalizeStyle({ 'color': item.defaultSubTextColor })
                          }, _toDisplayString(_ctx.$t("SessionQuestions.QuestionType.SingleSelect")), 5))
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("span", {
                        style: _normalizeStyle({ 'color': item.defaultSubTextColor }),
                        class: "valign"
                      }, _toDisplayString(_ctx.GetAttachedPrograms(item)), 5)
                    ])
                  ], 8, _hoisted_23)
                ], 4)
              ], 14, _hoisted_20))
            }), 128))
          ])
        ]))
  ]))
}