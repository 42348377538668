import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "landing-page" }
const _hoisted_2 = { class: "right-container-margin" }
const _hoisted_3 = { class: "main-heading-parent" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "sub-heading margin-top-15" }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { class: "select-question-type-parent" }
const _hoisted_9 = { class: "landing-action-text-margin bold-text" }
const _hoisted_10 = { class: "landing-main-content" }
const _hoisted_11 = { class: "action-middle-div" }
const _hoisted_12 = ["index"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "img-select imgSize display-flex justify-content-center" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "selectInformationStyle valign-parent60 item-position-from-image" }
const _hoisted_17 = { class: "valign" }
const _hoisted_18 = ["id"]
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 0,
  class: "action-middle-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("SessionQuestions.LandingPageHeading")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("SessionQuestions.LandingPageSubHeadingLine1")), 1),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("SessionQuestions.LandingPageSubHeadingLine2")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("SessionQuestions.CreateYourOwnQuestions")), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "action-side-width" }, null, -1)),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programQuestionTypes, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.Id,
              index: index,
              class: _normalizeClass(["inner-content", _ctx.evenIndex(index)==true?'action-content-width-left':'action-content-width-right'])
            }, [
              _createElementVNode("div", {
                class: "inner-content",
                onClick: ($event: any) => (_ctx.CreateNewSessionQuestion(item.Id))
              }, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("img", {
                    src: _ctx.$EnvironmentBaseURL + item.IconURL,
                    class: "icon-image margin-top-5"
                  }, null, 8, _hoisted_15)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("label", {
                      id: 'lblNewSessionQuestion'+index,
                      class: "hyperLink questionLinkFont margin-top-0"
                    }, _toDisplayString(_ctx.$t(item.QuestionTypeName)), 9, _hoisted_18),
                    _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", {
                      innerHTML: item.QuestionTypeDescription
                    }, null, 8, _hoisted_19)
                  ])
                ])
              ], 8, _hoisted_13),
              (_ctx.evenIndex(index))
                ? (_openBlock(), _createElementBlock("div", _hoisted_20))
                : _createCommentVNode("", true)
            ], 10, _hoisted_12))
          }), 128))
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "action-side-width" }, null, -1))
      ])
    ])
  ]))
}