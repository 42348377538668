import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "divVirtualMeetingOptions",
  class: "inline-flex100 row"
}
const _hoisted_2 = { class: "col-xl-4 option-div" }
const _hoisted_3 = {
  key: 0,
  class: "fa fa-check-circle check-circle"
}
const _hoisted_4 = { class: "heading bold-text" }
const _hoisted_5 = { class: "sub-heading" }
const _hoisted_6 = { class: "col-xl-4 option-div" }
const _hoisted_7 = {
  key: 0,
  class: "fa fa-check-circle check-circle"
}
const _hoisted_8 = { class: "heading bold-text" }
const _hoisted_9 = { class: "sub-heading" }
const _hoisted_10 = {
  key: 0,
  class: "col-xl-4 option-div"
}
const _hoisted_11 = {
  key: 0,
  class: "fa fa-check-circle check-circle"
}
const _hoisted_12 = { class: "heading bold-text" }
const _hoisted_13 = { class: "sub-heading" }
const _hoisted_14 = { class: "col-xl-4 option-div" }
const _hoisted_15 = {
  key: 0,
  class: "fa fa-check-circle check-circle"
}
const _hoisted_16 = { class: "heading bold-text" }
const _hoisted_17 = { class: "sub-heading" }
const _hoisted_18 = {
  key: 1,
  class: "col-xl-4 option-div"
}
const _hoisted_19 = {
  key: 0,
  class: "fa fa-check-circle check-circle"
}
const _hoisted_20 = { class: "heading bold-text" }
const _hoisted_21 = { class: "sub-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["border-radius-10", {'meeting-option': _ctx.localSelectedMeetingOption != _ctx.virtualMeetingEnum.YouTubeOrVimeo, 'meeting-option-selected': _ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.YouTubeOrVimeo, 'disabled-option': _ctx.isDisabled}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.VirtuaMeetingSelected(_ctx.virtualMeetingEnum.YouTubeOrVimeo)))
      }, [
        (_ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.YouTubeOrVimeo)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionVimeoOrYoutube")), 1),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionVimeoOrYoutubeSubHeading")), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["border-radius-10", {'meeting-option': _ctx.localSelectedMeetingOption != _ctx.virtualMeetingEnum.ProfessionalStreaming, 'meeting-option-selected': _ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.ProfessionalStreaming, 'disabled-option': _ctx.isDisabled}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.VirtuaMeetingSelected(_ctx.virtualMeetingEnum.ProfessionalStreaming)))
      }, [
        (_ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.ProfessionalStreaming)
          ? (_openBlock(), _createElementBlock("i", _hoisted_7))
          : _createCommentVNode("", true),
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionProfessionalStreaming")), 1),
        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.professionalMeetingSubHeading), 1)
      ], 2)
    ]),
    (_ctx.isEnableMeetAppLive())
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass(["border-radius-10", {'meeting-option': _ctx.localSelectedMeetingOption != _ctx.virtualMeetingEnum.MeetAppLive, 'meeting-option-selected': _ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.MeetAppLive, 'disabled-option': _ctx.isDisabled}]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.VirtuaMeetingSelected(_ctx.virtualMeetingEnum.MeetAppLive)))
          }, [
            (_ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.MeetAppLive)
              ? (_openBlock(), _createElementBlock("i", _hoisted_11))
              : _createCommentVNode("", true),
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionMeetAppLive")), 1),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionMeetAppLiveSubHeading")), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", {
        class: _normalizeClass(["border-radius-10", {'meeting-option': _ctx.localSelectedMeetingOption != _ctx.virtualMeetingEnum.ConferenceSoftware, 'meeting-option-selected': _ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.ConferenceSoftware, 'disabled-option': _ctx.isDisabled}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.VirtuaMeetingSelected(_ctx.virtualMeetingEnum.ConferenceSoftware)))
      }, [
        (_ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.ConferenceSoftware)
          ? (_openBlock(), _createElementBlock("i", _hoisted_15))
          : _createCommentVNode("", true),
        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionConferenceSoftware")), 1),
        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionConferenceSoftwareSubHeading")), 1)
      ], 2)
    ]),
    (_ctx.isEnableVideoOnDemand() && _ctx.moduleTypeNotEntity())
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", {
            class: _normalizeClass(["border-radius-10", {'meeting-option': _ctx.localSelectedMeetingOption != _ctx.virtualMeetingEnum.PreRecordedVideo, 'meeting-option-selected': _ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.PreRecordedVideo, 'disabled-option': _ctx.isDisabled}]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.VirtuaMeetingSelected(_ctx.virtualMeetingEnum.PreRecordedVideo)))
          }, [
            (_ctx.localSelectedMeetingOption == _ctx.virtualMeetingEnum.PreRecordedVideo)
              ? (_openBlock(), _createElementBlock("i", _hoisted_19))
              : _createCommentVNode("", true),
            _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionPrerecorderVideo")), 1),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.OptionPrerecorderVideoSubHeading")), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}