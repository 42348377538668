import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-10" }
const _hoisted_3 = { class: "top-margin-in-fields" }
const _hoisted_4 = {
  key: 0,
  class: "lineHeight"
}
const _hoisted_5 = { class: "margin-left-5 vonage-analytics-values" }
const _hoisted_6 = { class: "margin-left-5 vonage-analytics-values" }
const _hoisted_7 = { class: "margin-left-5 vonage-analytics-values" }
const _hoisted_8 = { class: "margin-left-5 vonage-analytics-values vonage-moderators" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "margin-left-5 vonage-analytics-values" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "margin-left-5 vonage-analytics-values" }
const _hoisted_13 = { class: "col-xl-2 margin-top-15" }
const _hoisted_14 = { class: "text-align-end" }
const _hoisted_15 = { class: "underline-color" }
const _hoisted_16 = { class: "margin-right-3" }
const _hoisted_17 = {
  key: 0,
  class: "information-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.StreamingEnded")), 1),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        (_ctx.ShowMeetAppLiveAnalytics())
          ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.TotalAtendees")), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_5, ": " + _toDisplayString(_ctx.vonageAnalyticslocal.Attendees), 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.TotalMetingMinutes")), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_6, ": " + _toDisplayString(_ctx.vonageAnalyticslocal.TotalMinutesOfMeeting), 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.AverageSeesionTime")), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_7, ": " + _toDisplayString(_ctx.vonageAnalyticslocal.AverageSessionTime) + " mins", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.Moderator")), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_8, ": " + _toDisplayString(_ctx.vonageAnalyticslocal.ModeratorsCount), 1)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("table", _hoisted_9, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.TotalAtendees")), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("sup", { class: "color-red" }, " #", -1))
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", _hoisted_10, ": " + _toDisplayString(_ctx.uniqueViewersDuringSession), 1)
                ])
              ]),
              (!_ctx.isRecorded)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                    _createElementVNode("td", null, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t("VirtualMeetings.RecordedOn")), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_12, ": " + _toDisplayString(_ctx.recordedOnMessage), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("u", _hoisted_15, [
            _createElementVNode("a", {
              class: "hyperLink",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadGraphComprehensiveDetails()))
            }, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("VirtualMeetings.Button.SeeDetails")), 1)
            ])
          ])
        ])
      ]),
      (_ctx.showDisplayAttendeesCountInformaion())
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "color-red" }, " # ", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.DisplayAttendeesCountInformaion")), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}