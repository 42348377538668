import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "evaluationQuestionListDiv",
  class: "item-listing toggleswitch"
}
const _hoisted_2 = {
  key: 1,
  class: "emptyDiv"
}
const _hoisted_3 = { class: "no-item-parent-div" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "no-item-message-container" }
const _hoisted_6 = { class: "no-item-message" }
const _hoisted_7 = {
  key: 2,
  class: "itemsDiv"
}
const _hoisted_8 = { class: "toggling-container" }
const _hoisted_9 = { class: "inline-flex" }
const _hoisted_10 = { class: "toggling-message" }
const _hoisted_11 = { class: "margin-right-15" }
const _hoisted_12 = {
  id: "lblApplyEvaluationToEntireProgram",
  class: "switch"
}
const _hoisted_13 = ["indexEvaluation", "disabled"]
const _hoisted_14 = ["onMouseover"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "checkbox-container" }
const _hoisted_17 = ["id", "value", "onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "question-container" }
const _hoisted_22 = { class: "inline-flex100" }
const _hoisted_23 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalPopupHeading,
          "body-heading": _ctx.modalPopupContentHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localevaluationQuestions==null || _ctx.localevaluationQuestions.length==0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, [
              _createElementVNode("label", {
                class: "font-size-30",
                innerHTML: _ctx.$t('EvaluationQuestions.LandingPageNoItemMessage')
              }, null, 8, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.$t("EvaluationQuestions.StartBytext")) + " ", 1),
              _createElementVNode("a", {
                class: "hyperLink questionLinkFont",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.OpenCreateNewQuestionpane()))
              }, _toDisplayString(_ctx.$t("EvaluationQuestions.CreatingQuestionLink")), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("EvaluationQuestions.ApplyEvaluationToEntireProgram")), 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    id: "inputApplyEvaluationToEntireProgram",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.globalProgramEvaluation) = $event)),
                    type: "checkbox",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleEvaluation($event)))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.globalProgramEvaluation]
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "slider round" }, null, -1))
                ])
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_draggable, {
              list: _ctx.localevaluationQuestions,
              tag: "ul",
              class: "list-group",
              handle: ".handle",
              animation: "500",
              onStart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.draggingStart($event, { passive: false }))),
              onEnd: _cache[7] || (_cache[7] = ($event: any) => (_ctx.draggingEnd($event, { passive: false })))
            }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, { type: "transition" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localevaluationQuestions, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.Id,
                        class: _normalizeClass(["programDiv program-item-default-bg-color item-saparator", [item.DisableItem ? '' : 'cursor-pointer']]),
                        indexEvaluation: index,
                        disabled: item.DisableItem,
                        style: _normalizeStyle([item.DisableItem ==true ? { 'pointer-events':'none','opacity':'0.4' } : ''])
                      }, [
                        _createElementVNode("div", {
                          class: "program-body inline-flex100",
                          style: _normalizeStyle({ 'background-color': item.defaultBGColor }),
                          onMouseover: ($event: any) => (_ctx.ShowDragAndDropIcon(index)),
                          onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowDragAndDropIcon(null)))
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["valign-parent50 cursor-move handle drag-drop-icon", {'visibility-hidden': (_ctx.hoverItemIndex !== index && !_ctx.isMobileView)}])
                          }, [
                            _createElementVNode("img", { src: _ctx.dragDropIcon }, null, 8, _hoisted_15)
                          ], 2),
                          _createElementVNode("div", _hoisted_16, [
                            _withDirectives(_createElementVNode("input", {
                              id: 'pq_' + item.Id,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedItems) = $event)),
                              type: "checkbox",
                              class: "noItemDisplay",
                              value: item,
                              onClick: ($event: any) => (_ctx.checkEvaluationQuestions(item,index,$event))
                            }, null, 8, _hoisted_17), [
                              [_vModelCheckbox, _ctx.selectedItems]
                            ])
                          ]),
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            class: "inline-flex100",
                            onClick: ($event: any) => (_ctx.selectEvaluationQuestion(item))
                          }, [
                            (item.Type==='Grade')
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "program-icon-Img round-image",
                                  src: _ctx.starratingIcon
                                }, null, 8, _hoisted_19))
                              : (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  src: _ctx.textFeedbackIcon
                                }, null, 8, _hoisted_20)),
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("span", {
                                    class: "description-heading",
                                    style: _normalizeStyle({ 'color': item.defaultTextColor })
                                  }, _toDisplayString(item.Question), 5)
                                ])
                              ]),
                              (item.Type==='Grade')
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    style: _normalizeStyle({ 'color': item.defaultSubTextColor })
                                  }, _toDisplayString(_ctx.$t("EvaluationQuestions.QuestionType.Grade")), 5))
                                : (_openBlock(), _createElementBlock("span", {
                                    key: 1,
                                    style: _normalizeStyle({ 'color': item.defaultSubTextColor })
                                  }, _toDisplayString(_ctx.$t("EvaluationQuestions.QuestionType.Text")), 5))
                            ])
                          ], 8, _hoisted_18)
                        ], 44, _hoisted_14)
                      ], 14, _hoisted_13))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["list"])
          ])
        ]))
  ]))
}