import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-success margin-top-20"
}
const _hoisted_2 = {
  key: 1,
  class: "alert alert-success margin-top-20"
}
const _hoisted_3 = {
  key: 0,
  class: "meeting-details-section"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "bold-text" }
const _hoisted_6 = { class: "example-hyperLink" }
const _hoisted_7 = { class: "example-hyperLink" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "bold-text" }
const _hoisted_11 = { class: "inline-flex100 margin-bottom-5" }
const _hoisted_12 = { class: "batch-first-radio" }
const _hoisted_13 = { class: "professional-strteaming-checkboxes" }
const _hoisted_14 = { class: "radio-text description" }
const _hoisted_15 = { class: "batch-other-radio width50" }
const _hoisted_16 = { class: "professional-strteaming-checkboxes" }
const _hoisted_17 = { class: "radio-text description" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = { class: "meeting-invalid-div top-margin-in-fields border-radius-10" }
const _hoisted_22 = { class: "meeting-passed-content" }
const _hoisted_23 = {
  key: 3,
  class: "top-margin-in-fields"
}
const _hoisted_24 = {
  id: "dvMeetAppLiveThresholdLimitReachedDetails",
  class: "alert alert-warning thresh-holdLimit-warning inline-flex100",
  role: "alert"
}
const _hoisted_25 = { class: "threshold-message message-content" }
const _hoisted_26 = { key: 4 }
const _hoisted_27 = { class: "bold-text no-margin" }
const _hoisted_28 = { class: "top-margin-in-fields" }
const _hoisted_29 = { class: "description" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 0,
  class: "margin-top-10"
}
const _hoisted_32 = { class: "bold-text no-margin" }
const _hoisted_33 = { class: "row top-margin-in-fields" }
const _hoisted_34 = { class: "col-md-12 padding-7" }
const _hoisted_35 = { class: "col-md-2 padding-4-13" }
const _hoisted_36 = { class: "professional-strteaming-labels no-margin description" }
const _hoisted_37 = { class: "col-md-8" }
const _hoisted_38 = { class: "col-md-1" }
const _hoisted_39 = { class: "row top-margin-in-fields" }
const _hoisted_40 = { class: "col-md-12 padding-7" }
const _hoisted_41 = { class: "col-md-2 padding-4-13" }
const _hoisted_42 = { class: "professional-strteaming-labels no-margin description" }
const _hoisted_43 = { class: "col-md-8" }
const _hoisted_44 = { class: "col-md-1" }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "rtmp-Streamkey-parent" }
const _hoisted_47 = { class: "makeTextItalic bold-text" }
const _hoisted_48 = { class: "parent-information-icon-div margin-top-5" }
const _hoisted_49 = { class: "description" }
const _hoisted_50 = { key: 5 }
const _hoisted_51 = { class: "bold-text" }
const _hoisted_52 = {
  key: 0,
  class: "margin-bottom-10 inline-flex100"
}
const _hoisted_53 = { class: "example-hyperLink" }
const _hoisted_54 = { id: "input_container" }
const _hoisted_55 = ["src"]
const _hoisted_56 = {
  key: 6,
  class: "top-margin-in-fields"
}
const _hoisted_57 = { class: "batch-first-radio" }
const _hoisted_58 = { class: "professional-streaming-zoom" }
const _hoisted_59 = { class: "radio-text description" }
const _hoisted_60 = { class: "batch-other-radio" }
const _hoisted_61 = { class: "professional-streaming-zoom" }
const _hoisted_62 = { class: "radio-text description" }
const _hoisted_63 = { key: 1 }
const _hoisted_64 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeetappPricingDetails = _resolveComponent("MeetappPricingDetails")!
  const _component_MeetAppLiveConsolidatedDetails = _resolveComponent("MeetAppLiveConsolidatedDetails")!
  const _component_VirtualMeetingMeetAppLive = _resolveComponent("VirtualMeetingMeetAppLive")!
  const _component_VideoOnDemandComponent = _resolveComponent("VideoOnDemandComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'virtual-meeting-edit-pane-conatiner' : _ctx.localselectedMeetingOption > 0})
  }, [
    _createElementVNode("div", null, [
      (_ctx.showRtmpPricingDetailsPopup)
        ? (_openBlock(), _createBlock(_component_MeetappPricingDetails, {
            key: 0,
            "show-pricing-popup": _ctx.showRtmpPricingDetailsPopup,
            "product-pricing-information": _ctx.rtmpLivePricingInformation,
            "pricing-popup-type": _ctx.pricingPopupType
          }, null, 8, ["show-pricing-popup", "product-pricing-information", "pricing-popup-type"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.showKeyCopiedMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[25] || (_cache[25] = _createElementVNode("i", {
                  class: "fa fa-check",
                  "aria-hidden": "true"
                }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.RtmpAnalytics.KeyCopied")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.showURLCopiedMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[26] || (_cache[26] = _createElementVNode("i", {
                  class: "fa fa-check",
                  "aria-hidden": "true"
                }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.RtmpAnalytics.URLCopied")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_ctx.ShowOthersSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.localselectedMeetingOption == _ctx.virtualMeetingEnum.YouTubeOrVimeo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.YouTubeVimeoHeading")), 1),
                _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.YouTubeExample")), 1),
                _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.VimeoExample")), 1)
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.localselectedMeetingOption ==
            _ctx.virtualMeetingEnum.ProfessionalStreaming ||
            _ctx.localselectedMeetingOption == _ctx.virtualMeetingEnum.MeetAppLive
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_ctx.ShowProfessionalStreamingOption() && _ctx.isEnableVideoOnDemand())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.ProfessionalStreamingOptions")), 1),
                      _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("label", _hoisted_13, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.VirtualMeetingType) = $event)),
                              type: "radio",
                              name: "icon-or-featured",
                              value: "RTMP",
                              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ProfessionalMeetingTypeChange($event.target.value)))
                            }, null, 544), [
                              [_vModelRadio, _ctx.VirtualMeetingType]
                            ]),
                            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t(
                    "VirtualMeetings.ExternalStreams.ProfessionalStreamingRTMP"
                  )), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("label", _hoisted_16, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.VirtualMeetingType) = $event)),
                              type: "radio",
                              name: "icon-or-featured",
                              value: "HLS",
                              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ProfessionalMeetingTypeChange($event.target.value)))
                            }, null, 544), [
                              [_vModelRadio, _ctx.VirtualMeetingType]
                            ]),
                            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.ProfessionalStreamingHLS")), 1)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.ShowAnalyticsSection())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      true
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createVNode(_component_MeetAppLiveConsolidatedDetails, {
                              "meet-app-live-analyticsdata": _ctx.vonageAnalytics,
                              "selected-programs-details": _ctx.selectedItem
                            }, null, 8, ["meet-app-live-analyticsdata", "selected-programs-details"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.localselectedMeetingOption ==
            _ctx.virtualMeetingEnum.ProfessionalStreaming && !_ctx.isRtmpStreamingValid()
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("VirtualMeetings.ProfessionalStreaming.RTMPPassed")), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.vonageThresholdLimitReached && _ctx.isRTMPVideo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _cache[30] || (_cache[30] = _createElementVNode("div", null, [
                    _createElementVNode("span", { class: "font-icon" }, [
                      _createElementVNode("i", {
                        class: "fa fa-exclamation-circle",
                        "aria-hidden": "true"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("VirtualMeetings.ProfessionalStreaming.TresholdLimitReached")), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.isRTMPVideo && _ctx.isRtmpStreamingValid() && !_ctx.vonageThresholdLimitReached
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.ProfessionalStreamingRTMPInfo")), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecordedSubHeadingLine1")), 1),
                  _createElementVNode("span", {
                    class: "hyperLink",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowRtmpPricingPopUp()))
                  }, _toDisplayString(_ctx.$t(
              "VirtualMeetings.ExternalStreams.PreRecordedSubHeadingPricingLink"
            )), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(
              "VirtualMeetings.ExternalStreams.PreRecordedSubHeadingForMoreInfo"
            )), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_29, [
                    _withDirectives(_createElementVNode("input", {
                      ref: "getRTMPStreamKeyCheckbox",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.streamKeyCheckbox) = $event)),
                      type: "checkbox",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.GetStreamKey()))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.streamKeyCheckbox]
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.PreRecorded.CheckBox")), 1)
                  ])
                ]),
                (_ctx.streamKeyCheckbox)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      (_ctx.selectedItem.Id > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                            _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t(
                "VirtualMeetings.ExternalStreams.ProfessionalStreamingRTMPUrlAndKey"
              )), 1),
                            _createElementVNode("div", _hoisted_33, [
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", _hoisted_35, [
                                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.StreamKey")) + " :", 1)
                                ]),
                                _createElementVNode("div", _hoisted_37, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: "inpRTMPStreamKey",
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.rtmpStreamKey) = $event)),
                                    type: "text",
                                    class: "form-control",
                                    disabled: "true"
                                  }, null, 512), [
                                    [_vModelText, _ctx.rtmpStreamKey]
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_38, [
                                  _createElementVNode("div", {
                                    class: "margin-top-5",
                                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.copyLinkToClipboard(_ctx.rtmpStreamKey, 'RTMPKey')))
                                  }, _cache[31] || (_cache[31] = [
                                    _createElementVNode("span", { class: "color-black" }, [
                                      _createElementVNode("i", { class: "fa fa-clone clone-icon" })
                                    ], -1)
                                  ]))
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_39, [
                              _createElementVNode("div", _hoisted_40, [
                                _createElementVNode("div", _hoisted_41, [
                                  _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.StreamUrl")) + " :", 1)
                                ]),
                                _createElementVNode("div", _hoisted_43, [
                                  _withDirectives(_createElementVNode("input", {
                                    id: "inpRTMPStreamKey",
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.rtmpStreamURl) = $event)),
                                    type: "text",
                                    class: "form-control",
                                    disabled: "true"
                                  }, null, 512), [
                                    [_vModelText, _ctx.rtmpStreamURl]
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_44, [
                                  _createElementVNode("div", {
                                    class: "margin-top-5",
                                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.copyLinkToClipboard(_ctx.rtmpStreamURl, 'RTMPURL')))
                                  }, _cache[32] || (_cache[32] = [
                                    _createElementVNode("span", { class: "color-black" }, [
                                      _createElementVNode("i", { class: "fa fa-clone clone-icon" })
                                    ], -1)
                                  ]))
                                ])
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.rtmpStreamKey), 1)
                              ])
                            ])
                          ])),
                      _createElementVNode("div", _hoisted_48, [
                        _createElementVNode("label", _hoisted_49, [
                          _withDirectives(_createElementVNode("input", {
                            ref: "makeRTMPStreamAvailableCheckbox",
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isLiveStreamRecorded) = $event)),
                            type: "checkbox",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.MakeRecordingAvailable()))
                          }, null, 512), [
                            [_vModelCheckbox, _ctx.isLiveStreamRecorded]
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.ExternalStreams.RTMPStreamAvailableInfo")) + ". ", 1)
                        ]),
                        _createElementVNode("i", {
                          class: "fa fa-info-circle available-till-info",
                          "aria-hidden": "true",
                          onMouseover: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getInformationString(true))),
                          onMouseleave: _cache[14] || (_cache[14] = ($event: any) => (_ctx.getInformationString(false)))
                        }, null, 32),
                        _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t(
                  "VirtualMeetings.ProfessionalStreaming.RecordedSessionPricingMessage"
                )), 513), [
                          [_vShow, _ctx.isRecordedSessionMessage]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.VirtualMeetingType !== 'RTMP' &&
            !_ctx.isRTMPVideo &&
            _ctx.VirtualMeetingType !== 'VonageMUX'
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.linkFieldHeading), 1),
                (
            _ctx.localselectedMeetingOption === _ctx.virtualMeetingEnum.ConferenceSoftware
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t(
                "VirtualMeetings.ExternalStreams.ConferenceSoftwareInformation"
              )), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_54, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedItem.VirtualMeetingLink) = $event)),
                    type: "text",
                    class: "form-control input-field",
                    onKeyup: _cache[16] || (_cache[16] = ($event: any) => (_ctx.OnInvitaionLinkChange())),
                    onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.OnInvitaionLinkChange())),
                    onPaste: _cache[18] || (_cache[18] = ($event: any) => (_ctx.OnInvitaionLinkChange())),
                    onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.OnInvitaionLinkChange()))
                  }, null, 544), [
                    [_vModelText, _ctx.selectedItem.VirtualMeetingLink]
                  ]),
                  (_ctx.ShowImageIcon())
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        id: "input_img",
                        src: _ctx.VirtualMeetingIcon,
                        class: "video-type-icon"
                      }, null, 8, _hoisted_55))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (
          _ctx.localselectedMeetingOption == _ctx.virtualMeetingEnum.ConferenceSoftware &&
            _ctx.VirtualMeetingType == 'Zoom' && _ctx.localSelectedVodBase.Moduletype.toLowerCase() !== 'entity'
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                _createElementVNode("div", _hoisted_57, [
                  _createElementVNode("label", _hoisted_58, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.selectedItem.HasExternalZoomLink) = $event)),
                      type: "radio",
                      name: "zoomexternallink",
                      value: "false",
                      onChange: _cache[21] || (_cache[21] = ($event: any) => (
                _ctx.ProfessionalMeetingZoomExternalLinkTypeChange(
                  $event.target.value
                )
              ))
                    }, null, 544), [
                      [_vModelRadio, _ctx.selectedItem.HasExternalZoomLink]
                    ]),
                    _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("VirtualMeetings.ProfessionalStreaming.Zoom.EmbeddedZoom")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("label", _hoisted_61, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.selectedItem.HasExternalZoomLink) = $event)),
                      type: "radio",
                      name: "zoomexternallink",
                      value: "true",
                      onChange: _cache[23] || (_cache[23] = ($event: any) => (
                _ctx.ProfessionalMeetingZoomExternalLinkTypeChange(
                  $event.target.value
                )
              ))
                    }, null, 544), [
                      [_vModelRadio, _ctx.selectedItem.HasExternalZoomLink]
                    ]),
                    _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.$t("VirtualMeetings.ProfessionalStreaming.Zoom.ExternalZoom")), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showMeetAppLive())
      ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
          _createVNode(_component_VirtualMeetingMeetAppLive, {
            "selected-item": _ctx.selectedItem,
            "show-studio-link": _ctx.showStudioLink,
            "module-type": _ctx.GetModuleType(),
            "vonage-threshold-limit-reached": _ctx.vonageThresholdLimitReached
          }, null, 8, ["selected-item", "show-studio-link", "module-type", "vonage-threshold-limit-reached"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowPrerecordedSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
          _createVNode(_component_VideoOnDemandComponent, {
            "selected-vod-base-classs": _ctx.localSelectedVodBase,
            "vonage-threshold-limit-reached": _ctx.vonageThresholdLimitReached,
            onThreshHold: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showThreshHold($event)))
          }, null, 8, ["selected-vod-base-classs", "vonage-threshold-limit-reached"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}