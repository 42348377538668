import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-title" }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "evaluation-report-popup-content" }
const _hoisted_9 = { class: "error-vuelidate valign" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t("EvaluationQuestions.EvaluationReportHeader")), 1),
                _createElementVNode("a", {
                  href: "javascript:void(0)",
                  "aria-hidden": "true",
                  class: "margin-right-10",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.Cancel();})
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("i", {
                    class: "fa fa-times close-icon-color",
                    "aria-hidden": "true"
                  }, null, -1)
                ]))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    id: "emailAddress",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.v$.userEmail.$model) = $event)),
                    placeholder: "name@domain.com",
                    maxlength: "70",
                    type: "text",
                    class: _normalizeClass(["form-control input-lg stretch", _ctx.status(_ctx.v$.userEmail)])
                  }, null, 2), [
                    [_vModelText, _ctx.v$.userEmail.$model]
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.v$.userEmail.$error)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("Message.EnterValidEmail")), 1))
                      : _createCommentVNode("", true)
                  ])
                ], 512), [
                  [_vShow, !_ctx.evaluationReportSent]
                ]),
                _withDirectives(_createElementVNode("div", null, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.message), 1)
                ], 512), [
                  [_vShow, _ctx.evaluationReportSent]
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.Cancel();})
                }, _toDisplayString(_ctx.$t("Button.Close")), 1),
                _withDirectives(_createElementVNode("button", {
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.clickYes(_ctx.v$);})
                }, _toDisplayString(_ctx.$t("Button.Send")), 513), [
                  [_vShow, !_ctx.evaluationReportSent]
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}