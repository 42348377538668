import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "right-container-margin" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "right-container-nav" }
const _hoisted_4 = { class: "width25" }
const _hoisted_5 = { class: "width50" }
const _hoisted_6 = { class: "main-heading primary-heading" }
const _hoisted_7 = { class: "margin-top-20" }
const _hoisted_8 = { class: "import-description" }
const _hoisted_9 = { class: "margin-top-40 import-button-seperator" }
const _hoisted_10 = { class: "font-size-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreviewBulkData = _resolveComponent("PreviewBulkData")!
  const _component_UploadBulkData = _resolveComponent("UploadBulkData")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isPreviewVisible)
      ? (_openBlock(), _createBlock(_component_PreviewBulkData, {
          key: 0,
          "preview-imported-data": _ctx.previewBulkData,
          onHideImportPreview: _ctx.HideImportPreview
        }, null, 8, ["preview-imported-data", "onHideImportPreview"]))
      : (_ctx.isUploadBulkFileVisible)
        ? (_openBlock(), _createBlock(_component_UploadBulkData, {
            key: 1,
            "module-type": _ctx.moduleType,
            "clicked-button-type": _ctx.clickedButtonType,
            onCloseBulkUpload: _ctx.CloseBulkUpload,
            onShowPreview: _ctx.ShowPreviewPane
          }, null, 8, ["module-type", "clicked-button-type", "onCloseBulkUpload", "onShowPreview"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseImport()))
                }, _toDisplayString(_ctx.$t("Button.Close")), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.importFileData.Heading), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.importFileData.Description), 1),
              _createVNode(_component_ActionButton, {
                "action-button-data": _ctx.importFileData.FileOperation[_ctx.actionButtonType.Create],
                class: "margin-top-40",
                onShowUploadBulkData: _ctx.ShowUploadBulkData
              }, null, 8, ["action-button-data", "onShowUploadBulkData"]),
              _createElementVNode("div", _hoisted_9, [
                _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "width45" }, null, -1)),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Or')), 1),
                _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "width45" }, null, -1))
              ]),
              _createVNode(_component_ActionButton, {
                "action-button-data": _ctx.importFileData.FileOperation[_ctx.actionButtonType.Update],
                class: "margin-top-40",
                onShowUploadBulkData: _ctx.ShowUploadBulkData
              }, null, 8, ["action-button-data", "onShowUploadBulkData"])
            ])
          ]))
  ]))
}