import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = { class: "inline-flex100 justify-content-space-between align-items-center margin-bottom-10" }
const _hoisted_3 = { class: "width55" }
const _hoisted_4 = { class: "font-size-20 font-weight-bold no-margin" }
const _hoisted_5 = {
  key: 0,
  class: "action-button"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "margin-left-3" }
const _hoisted_8 = {
  key: 1,
  class: "ajax-loader"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "description" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "iconImg round-icon" }
const _hoisted_15 = {
  key: 0,
  class: "fa fa-file-text font-icon"
}
const _hoisted_16 = {
  key: 1,
  class: "fa fa-bar-chart font-icon"
}
const _hoisted_17 = { class: "question-format" }
const _hoisted_18 = { class: "valign" }
const _hoisted_19 = { class: "time description-heading" }
const _hoisted_20 = { class: "valign" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "manage-div-width" }
const _hoisted_24 = { class: "dropdown inline-flex" }
const _hoisted_25 = { "data-toggle": "dropdown" }
const _hoisted_26 = ["src"]
const _hoisted_27 = { class: "dropdown-menu text-left" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteFolder'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("Program.EvaluationQuestions")), 1)
      ]),
      (!_ctx.localEvaluationLoading && _ctx.selectedProgramId>0 && _ctx.localEvaluationQuestions!=null && _ctx.localEvaluationQuestions.length>0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.excelIcon,
              class: "excel-icon-size"
            }, null, 8, _hoisted_6),
            _createElementVNode("a", {
              class: "hyperLink",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ExportResultsToExcel && _ctx.ExportResultsToExcel(...args)))
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.localEvaluationLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_9)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.localEvaluationQuestions==null || _ctx.localEvaluationQuestions.length==0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("Program.EvaluationQuestions.NoItemMessage")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localEvaluationQuestions, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.Id,
                    class: "repeat-eval-session-item"
                  }, [
                    _createElementVNode("div", _hoisted_14, [
                      (item.Type=='Text')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                        : (_openBlock(), _createElementBlock("i", _hoisted_16))
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(item.Question), 1),
                        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                        _createElementVNode("span", _hoisted_20, [
                          (item.Type==='Grade')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t("EvaluationQuestions.QuestionType.Grade")), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t("EvaluationQuestions.QuestionType.Text")), 1))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("img", {
                            src: _ctx.manageIcon,
                            class: "manage-icon"
                          }, null, 8, _hoisted_26)
                        ]),
                        _createElementVNode("ul", _hoisted_27, [
                          (!_ctx.globalProgramEvaluation)
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 0,
                                class: "export-btn",
                                onClick: ($event: any) => (_ctx.DelinkEvaluationQuestion(item))
                              }, [
                                _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Remove")), 1)
                              ], 8, _hoisted_28))
                            : _createCommentVNode("", true),
                          _createElementVNode("li", {
                            class: "export-btn",
                            onClick: ($event: any) => (_ctx.ExportSingleEvaluationResultToExcel(item))
                          }, [
                            _createElementVNode("a", null, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
                          ], 8, _hoisted_29)
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ]))
        ]))
  ]))
}