import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-listing overflow-auto" }
const _hoisted_2 = { class: "inline-flex100 justify-content-space-between align-items-center margin-bottom-10" }
const _hoisted_3 = { class: "width55" }
const _hoisted_4 = { class: "section-heading no-margin" }
const _hoisted_5 = {
  key: 0,
  class: "action-button"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "margin-left-3" }
const _hoisted_8 = {
  key: 1,
  class: "ajax-loader"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "description"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "text-position" }
const _hoisted_15 = { class: "description-heading" }
const _hoisted_16 = {
  key: 0,
  class: "alert alert-success"
}
const _hoisted_17 = { class: "inline-flex100" }
const _hoisted_18 = { class: "session-question-link-div" }
const _hoisted_19 = ["href", "title"]
const _hoisted_20 = { class: "copy-link-container" }
const _hoisted_21 = { class: "text-position" }
const _hoisted_22 = { class: "small-description color-grey" }
const _hoisted_23 = { class: "margin-top-15" }
const _hoisted_24 = { class: "iconImg round-icon" }
const _hoisted_25 = { class: "badge" }
const _hoisted_26 = {
  key: 0,
  class: "fa fa-comments-o font-icon-size"
}
const _hoisted_27 = {
  key: 1,
  class: "fa fa-bar-chart font-icon-size"
}
const _hoisted_28 = { class: "question-format" }
const _hoisted_29 = { class: "valign" }
const _hoisted_30 = { class: "time description-heading" }
const _hoisted_31 = { class: "valign" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "manage-div-width" }
const _hoisted_35 = { class: "dropdown inline-flex valign" }
const _hoisted_36 = { "data-toggle": "dropdown" }
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "dropdown-menu text-left" }
const _hoisted_39 = { class: "export-btn" }
const _hoisted_40 = ["href"]
const _hoisted_41 = ["onClick"]
const _hoisted_42 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('DeleteFolder'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("Program.SessionQuestions")), 1)
      ]),
      (!_ctx.localSessionLoading && _ctx.localSessionQuestions !== null && _ctx.localSessionQuestions.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _ctx.excelIcon,
              class: "excel-icon-size"
            }, null, 8, _hoisted_6),
            _createElementVNode("a", {
              class: "hyperLink",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ExportResultsToExcel && _ctx.ExportResultsToExcel(...args)))
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.localSessionLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_9)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.localSessionQuestions === null || _ctx.localSessionQuestions.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (!_ctx.localSessionLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("Program.SessionQuestions.NoItemMessage")), 1))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Program.SessionQuestions.ShareWithPresenters")), 1)
                ]),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (_ctx.showCopiedMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _cache[3] || (_cache[3] = _createElementVNode("i", {
                            class: "fa fa-check",
                            "aria-hidden": "true"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("a", {
                      class: "hyperLink",
                      href: _ctx.localprogramQuestionPublishedLink,
                      target: "_blank",
                      title: _ctx.localprogramQuestionPublishedLink
                    }, _toDisplayString(_ctx.localprogramQuestionPublishedLink), 9, _hoisted_19)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("a", {
                      class: "hyperLink",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyPublicLinkToClipboard && _ctx.copyPublicLinkToClipboard(...args)))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("Program.SessionQuestions.CopyLink")), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.$t("Program.SessionQuestions.LinkExpireOn")) + _toDisplayString(_ctx.localprogramQuestionLinkExpirationDate), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localSessionQuestions, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.Id,
                      class: "repeat-eval-session-item"
                    }, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(item.ProgramResponseCount), 1),
                        (item.typeId===1)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                          : (_openBlock(), _createElementBlock("i", _hoisted_27))
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("span", _hoisted_30, _toDisplayString(item.questionTitle), 1),
                          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("span", _hoisted_31, [
                            (item.typeId===1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t("SessionQuestions.QuestionType.Text")), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.$t("SessionQuestions.QuestionType.SingleSelect")), 1))
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createElementVNode("img", {
                              src: _ctx.manageIcon,
                              class: "manage-icon"
                            }, null, 8, _hoisted_37)
                          ]),
                          _createElementVNode("ul", _hoisted_38, [
                            _createElementVNode("li", _hoisted_39, [
                              _createElementVNode("a", {
                                href: item.ProgramQuestionResponseAccessKey,
                                target: "_blank"
                              }, _toDisplayString(_ctx.$t("Button.View")), 9, _hoisted_40)
                            ]),
                            _createElementVNode("li", {
                              class: "export-btn",
                              onClick: ($event: any) => (_ctx.DelinkProgramQuestion(item))
                            }, [
                              _createElementVNode("a", null, _toDisplayString(_ctx.$t("Button.Remove")), 1)
                            ], 8, _hoisted_41),
                            _createElementVNode("li", {
                              class: "export-btn",
                              onClick: ($event: any) => (_ctx.ExportSingleProgramQuestionResultToExcel(item))
                            }, [
                              _createElementVNode("a", null, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
                            ], 8, _hoisted_42)
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]))
        ]))
  ]))
}