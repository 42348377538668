import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-heading"
}
const _hoisted_2 = {
  key: 1,
  class: "top-margin-in-fields"
}
const _hoisted_3 = { class: "description" }
const _hoisted_4 = {
  key: 2,
  class: "top-margin-in-fields"
}
const _hoisted_5 = { class: "new-sub-section-heading" }
const _hoisted_6 = {
  key: 0,
  class: "top-margin-in-fields"
}
const _hoisted_7 = {
  id: "dvMeetAppLiveThresholdLimitReachedDetails",
  class: "alert alert-warning thresh-holdLimit-warning inline-flex100",
  role: "alert"
}
const _hoisted_8 = { class: "threshold-message message-content" }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VirtualMeetingOptions = _resolveComponent("VirtualMeetingOptions")!
  const _component_VirtualMeetingEditPane = _resolveComponent("VirtualMeetingEditPane")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      (_ctx.IsEventModule())
        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t("VirtualMeetings.Heading.Title")), 1))
        : _createCommentVNode("", true),
      (_ctx.IsEventModule())
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("VirtualMeetings.SubHeading")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.IsEventModule())
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("VirtualMeetings.AvailableOptions")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_VirtualMeetingOptions, {
        "selected-meeting-option": _ctx.selectedMeetingOption,
        "selected-item": _ctx.selectedItem,
        "module-type": _ctx.localSelectedVodBase.Moduletype,
        onSelectedMeetingType: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SelectedMeetingTypeChange($event)))
      }, null, 8, ["selected-meeting-option", "selected-item", "module-type"])
    ]),
    (_ctx.vonageThresholdLimitReached)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-icon" }, [
                _createElementVNode("i", {
                  class: "fa fa-exclamation-circle",
                  "aria-hidden": "true"
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.GetThresholdLimitReachedMsg()), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", null, [
            _createVNode(_component_VirtualMeetingEditPane, {
              "selected-meeting-option": _ctx.selectedMeetingOption,
              "selected-item": _ctx.selectedItem,
              "selected-vod-base": _ctx.localSelectedVodBase,
              "vonage-threshold-limit-reached": _ctx.vonageThresholdLimitReached,
              onThreshHold: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showThreshHold($event)))
            }, null, 8, ["selected-meeting-option", "selected-item", "selected-vod-base", "vonage-threshold-limit-reached"])
          ])
        ]))
  ]))
}