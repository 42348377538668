import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "margin-top-30"
}
const _hoisted_2 = { class: "inline-flex100 align-items-center" }
const _hoisted_3 = { class: "width55" }
const _hoisted_4 = { class: "heading font-weight-600 no-margin" }
const _hoisted_5 = { class: "excel-icon" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "margin-left-3" }
const _hoisted_8 = { class: "margin-top-5" }
const _hoisted_9 = { class: "margin-top-10 table-div" }
const _hoisted_10 = { role: "table" }
const _hoisted_11 = { role: "rowgroup" }
const _hoisted_12 = { role: "row" }
const _hoisted_13 = { role: "columnheader" }
const _hoisted_14 = { role: "columnheader" }
const _hoisted_15 = { role: "columnheader" }
const _hoisted_16 = { role: "columnheader" }
const _hoisted_17 = { role: "rowgroup" }
const _hoisted_18 = { role: "cell" }
const _hoisted_19 = { class: "name-container" }
const _hoisted_20 = {
  key: 0,
  class: "moderator-icon"
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }
const _hoisted_23 = { role: "cell" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { role: "cell" }
const _hoisted_26 = { class: "joined-time" }
const _hoisted_27 = { role: "cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_loading, {
        "show-loading": _ctx.showLoading,
        "border-color": _ctx.loaderBorderColor
      }, null, 8, ["show-loading", "border-color"])
    ]),
    (_ctx.vonageAnalyticslocal.AttendeeDatas !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("VirtualMeetings.comprehensiveDetails")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.excelIcon,
                class: "excel-icon-size"
              }, null, 8, _hoisted_6),
              _createElementVNode("a", {
                class: "hyperLink",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ExportSingleVonageAnalyticsToExcel()))
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("VirtualMeetings.AvailableDetaiilsTill")), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("table", _hoisted_10, [
              _createElementVNode("thead", _hoisted_11, [
                _createElementVNode("tr", _hoisted_12, [
                  _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.$t("Name")), 1),
                  _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t("Email")), 1),
                  _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("VirtualMeetings.JoinedTimes")), 1),
                  _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("VirtualMeetings.TotalMinutes")), 1)
                ])
              ]),
              _createElementVNode("tbody", _hoisted_17, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vonageAnalyticslocal.AttendeeDatas, (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.Id,
                    role: "row"
                  }, [
                    _createElementVNode("td", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        (item.IsModerator)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[1] || (_cache[1] = [
                              _createElementVNode("i", {
                                class: "fa fa-user-secret",
                                "aria-hidden": "true"
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (item.FirstName !== '' && item.LastName !== '')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(item.FirstName) + " " + _toDisplayString(item.LastName), 1))
                          : (_openBlock(), _createElementBlock("div", _hoisted_22, " - "))
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_23, [
                      (item.Email !== '')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.GetTableData(item.Email)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, _toDisplayString(item.JoinedTimes), 1)
                    ]),
                    _createElementVNode("td", _hoisted_27, _toDisplayString(item.TotalMinutes), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}