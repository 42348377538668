import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "dvAddInformation",
  class: "flex-container scroll-top"
}
const _hoisted_2 = { class: "clear-both" }
const _hoisted_3 = { class: "clear-both" }
const _hoisted_4 = { class: "inline-flex100 align-items-center" }
const _hoisted_5 = { class: "width15" }
const _hoisted_6 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_7 = {
  key: 1,
  class: "main-heading primary-heading"
}
const _hoisted_8 = {
  key: 0,
  class: "width15"
}
const _hoisted_9 = {
  key: 0,
  class: "padding-top-20"
}
const _hoisted_10 = {
  key: 1,
  class: "margin-top-10"
}
const _hoisted_11 = { class: "inline-flex" }
const _hoisted_12 = { class: "padding-3 margin-left-5 description" }
const _hoisted_13 = { class: "inline-flex100 text-center margin-top-30 min-height-45" }
const _hoisted_14 = { class: "width40 margin-left8" }
const _hoisted_15 = { value: "All" }
const _hoisted_16 = { value: "" }
const _hoisted_17 = ["value"]
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "item-position-from-image valign-parent50 width70" }
const _hoisted_20 = { class: "valign description" }
const _hoisted_21 = { class: "description-heading" }
const _hoisted_22 = {
  key: 0,
  class: "margin-left4"
}
const _hoisted_23 = {
  key: 1,
  class: "margin-left4"
}
const _hoisted_24 = { class: "valign-parent55 width30 text-right" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnBackProgramEdit()))
            }, _toDisplayString(_ctx.$t("ProgramAddInformation.Close")), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["width70", { 'margin-left-20': _ctx.isMobileView}])
          }, [
            (_ctx.isBulkOperation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("ProgramAddInformation.LinkHeading")), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("ProgramAddInformation.Heading")), 1))
          ], 2),
          (_ctx.isBulkOperation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveLinkedInformation()))
                }, _toDisplayString(_ctx.$t("Button.Save")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.isBulkOperation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.visibleValidationSummary)
            ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                key: 0,
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isBulkOperation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.overwriteLinkedInformation) = $event)),
              type: "checkbox",
              class: "largerCheckbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.overwriteLinkedInformation]
            ]),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("ProgramAddInformation.OverwritePreviouslyLinkedItems")), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("ProgramAddInformation.BulkOperationOverwriteDescription")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-right", {'width45': _ctx.isMobileView,'width35':!_ctx.isMobileView}])
      }, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t("ProgramAddInformation.ViewFolders")), 1)
      ], 2),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("select", {
          id: "ddlstFolders",
          class: "form-control selectBox",
          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onFolderChange($event.target.value)))
        }, [
          _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$t("ProgramAddInformation.All")), 1),
          _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t("ProgramAddInformation.TopLevel")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localcustomCategoryFolders, (folder) => {
            return (_openBlock(), _createElementBlock("option", {
              key: folder.Id,
              value: folder.Name
            }, _toDisplayString(folder.Name), 9, _hoisted_17))
          }), 128))
        ], 32)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredInformations, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.Id,
        class: "information-item"
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: _ctx.GetImageUrl(item.ThumbUrl),
            class: "iconImg"
          }, null, 8, _hoisted_18)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("span", _hoisted_21, _toDisplayString(item.Title), 1),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", null, [
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa fa-folder-open" }, null, -1)),
              (item.FolderName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(item.FolderName), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t("ProgramAddInformation.TopLevel")), 1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          (item.IsLinked)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-secondary btn-size-default valign",
                onClick: ($event: any) => (_ctx.removeItem(item))
              }, _toDisplayString(_ctx.$t("Button.Remove")), 9, _hoisted_25))
            : _createCommentVNode("", true),
          (!item.IsLinked)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-primary btn-size-default valign",
                onClick: ($event: any) => (_ctx.addItem(item))
              }, _toDisplayString(_ctx.$t("Button.Add")), 9, _hoisted_26))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}