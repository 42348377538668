import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "evaluationQuestionEditMode",
  class: "default-page-height editPane scroll-top"
}
const _hoisted_2 = { class: "right-container-margin" }
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_6 = {
  key: 1,
  class: "main-heading primary-heading"
}
const _hoisted_7 = { class: "inline-flex" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "padding-top-20" }
const _hoisted_10 = {
  key: 0,
  class: "alert alert-success margin-top-10"
}
const _hoisted_11 = { class: "top-margin-in-fields margin-bottom-15" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = {
  key: 0,
  class: "display-block"
}
const _hoisted_14 = {
  key: 1,
  class: "display-block"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["id", "value"]
const _hoisted_17 = ["src"]
const _hoisted_18 = ["hidden"]
const _hoisted_19 = { class: "margin-left-3" }
const _hoisted_20 = { class: "top-margin-in-fields" }
const _hoisted_21 = { class: "font-size-16" }
const _hoisted_22 = { class: "color-red" }
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { class: "margin-top-5" }
const _hoisted_25 = { class: "inline-flex" }
const _hoisted_26 = ["checked"]
const _hoisted_27 = { class: "margin-left-5 margin-top-3" }
const _hoisted_28 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_EvaluationQuestionListing = _resolveComponent("EvaluationQuestionListing")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('EvaluationQuestions.DeleteEvaluationQuestion'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
          }, _toDisplayString(_ctx.$t("CancelButton")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.localEvaluationQuestion.Id>0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("EvaluationQuestions.EditEvaluationQuestion")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("EvaluationQuestions.AddEvaluationQuestion")), 1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            id: "btnSaveEvaluationQuestion",
            class: "btn btn-primary btn-size-default",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
          }, _toDisplayString(_ctx.$t("SaveButton")), 1),
          (_ctx.localEvaluationQuestion.Id>0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn btn-delete",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Delete()))
              }, [
                _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.visibleValidationSummary)
          ? (_openBlock(), _createBlock(_component_ValidationSummary, {
              key: 0,
              "error-fields": _ctx.validationErrorFields,
              onCloseClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseValidationSummary($event)))
            }, null, 8, ["error-fields"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.messages.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          (_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("label", _hoisted_13, _toDisplayString(_ctx.$t("EvaluationQuestions.Type")) + ":", 1))
            : (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.$t("EvaluationQuestions.QuestionType")) + ":", 1)),
          _withDirectives(_createElementVNode("select", {
            id: "selQuestiontype",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localEvaluationQuestion.Type) = $event)),
            class: "form-control question-type-width selectBox display-inline-block",
            disabled: _ctx.localEvaluationQuestion.Id>0
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programEvaluationQuestionTypes, (questiontype) => {
              return (_openBlock(), _createElementBlock("option", {
                id: 'evaluationQuestion'+questiontype.Id,
                key: questiontype.Id,
                value: questiontype.Type
              }, _toDisplayString(_ctx.$t(questiontype.QuestionTypeName)), 9, _hoisted_16))
            }), 128))
          ], 8, _hoisted_15), [
            [_vModelSelect, _ctx.localEvaluationQuestion.Type]
          ]),
          (_ctx.localEvaluationQuestion.Id>0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "export-excel action-button",
                onTouchstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ExportSingleEvaluationQuestionResultToExcel())),
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ExportSingleEvaluationQuestionResultToExcel()))
              }, [
                _createElementVNode("img", {
                  src: _ctx.excelIcon,
                  class: "excel-icon-size"
                }, null, 8, _hoisted_17),
                _createElementVNode("a", {
                  class: "hyperLink",
                  hidden: _ctx.isMobileView
                }, [
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("ExportToExcel")), 1)
                ], 8, _hoisted_18)
              ], 32))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("label", _hoisted_21, [
            _createTextVNode(_toDisplayString(_ctx.$t("EvaluationQuestions.Question")) + " ", 1),
            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("Asteric")), 1)
          ]),
          _withDirectives(_createElementVNode("textarea", {
            id: "textAreaEvaluationQuestion",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.v$.localEvaluationQuestion.Question.$model) = $event)),
            rows: "4",
            class: _normalizeClass([_ctx.status(_ctx.v$.localEvaluationQuestion.Question), "form-control textarea-size"]),
            type: "text",
            placeholder: _ctx.$t('EvaluationQuestions.QuestionPlaceholder'),
            maxlength: "100"
          }, null, 10, _hoisted_23), [
            [_vModelText, _ctx.v$.localEvaluationQuestion.Question.$model]
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("label", _hoisted_25, [
            _createElementVNode("input", {
              type: "checkbox",
              class: "largerCheckbox",
              checked: _ctx.localEvaluationQuestion.Anynomous,
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.IsAnonymousClicked($event)))
            }, null, 8, _hoisted_26),
            _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("EvaluationQuestions.MakeAnonymous")), 1)
          ])
        ])
      ]),
      (!_ctx.localGlobalProgramEvaluation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
            _createVNode(_component_EvaluationQuestionListing, {
              "selected-item": _ctx.localEvaluationQuestion,
              "program-items": _ctx.programItems,
              "selected-program-ids": _ctx.selectedProgramIds,
              onProgramSetSelectedProgramItems: _cache[10] || (_cache[10] = ($event: any) => (_ctx.SetSelectedProgramItems($event)))
            }, null, 8, ["selected-item", "program-items", "selected-program-ids"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}