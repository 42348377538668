import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex-container toggleswitch background-white border-radius-20" }
const _hoisted_2 = { class: "new-nav-bar-container inline-flex background-white" }
const _hoisted_3 = {
  key: 0,
  class: "bttons"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "new-left-side-tool-bar" }
const _hoisted_8 = { class: "no-item margin-left-10" }
const _hoisted_9 = {
  id: "spnSelectAllItems",
  class: "select-all-position bold-text"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "margin-left-5" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "margin-left-5" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "margin-left-5" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "margin-left-5" }
const _hoisted_18 = { class: "inner-flex-container" }
const _hoisted_19 = { class: "left-inner-flex-container" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "area100" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "left-side-tool-bar noItemDisplay width15" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = {
  id: "spnSelectAllEvaluationQuestions",
  class: "select-all-position bold-text"
}
const _hoisted_27 = ["disabled"]
const _hoisted_28 = { class: "dropdown-menu text-left" }
const _hoisted_29 = { class: "inner-flex-container" }
const _hoisted_30 = { class: "left-inner-flex-container" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { class: "left-side-tool-bar noItemDisplay width15" }
const _hoisted_34 = { class: "" }
const _hoisted_35 = {
  id: "spnSelectAllSessionQuestions",
  class: "select-all-position bold-text"
}
const _hoisted_36 = ["disabled"]
const _hoisted_37 = { class: "inner-flex-container" }
const _hoisted_38 = { class: "left-inner-flex-container" }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = { key: 0 }
const _hoisted_41 = {
  key: 1,
  class: "bttons create-new-btn-div"
}
const _hoisted_42 = ["src"]
const _hoisted_43 = ["src"]
const _hoisted_44 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_VueTabs = _resolveComponent("VueTabs")!
  const _component_ProgramListView = _resolveComponent("ProgramListView")!
  const _component_ProgramEditItemPane = _resolveComponent("ProgramEditItemPane")!
  const _component_ProgramBatchEditPane = _resolveComponent("ProgramBatchEditPane")!
  const _component_ImportFile = _resolveComponent("ImportFile")!
  const _component_ProgramTrackListView = _resolveComponent("ProgramTrackListView")!
  const _component_ProgramTagListView = _resolveComponent("ProgramTagListView")!
  const _component_EvaluationQuestionsListView = _resolveComponent("EvaluationQuestionsListView")!
  const _component_EvaluationQuestionsLandingPage = _resolveComponent("EvaluationQuestionsLandingPage")!
  const _component_EvaluationQuestionsEditItemPane = _resolveComponent("EvaluationQuestionsEditItemPane")!
  const _component_EvaluationQuestionBatchPane = _resolveComponent("EvaluationQuestionBatchPane")!
  const _component_EvaluationQuestionExportResultsToEmailModalPopup = _resolveComponent("EvaluationQuestionExportResultsToEmailModalPopup")!
  const _component_SessionQuestionsListView = _resolveComponent("SessionQuestionsListView")!
  const _component_SessionQuestionsLandingPage = _resolveComponent("SessionQuestionsLandingPage")!
  const _component_SessionQuestionsEditItemPane = _resolveComponent("SessionQuestionsEditItemPane")!
  const _component_SessionQuestionBatchPane = _resolveComponent("SessionQuestionBatchPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.modalHeader,
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VueTabs, {
        "tab-view-model": _ctx.programTabListTabEntityList,
        onSelectedTab: _ctx.selectedTab
      }, null, 8, ["tab-view-model", "onSelectedTab"]),
      _cache[47] || (_cache[47] = _createElementVNode("div", { class: "width30 no-mobile-item-display" }, [
        _createElementVNode("div", { class: "toggling-container margin-top-10" })
      ], -1)),
      (!_ctx.isTabView && !_ctx.isMobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              id: "btnCreateNewProgramItem",
              class: _normalizeClass([{ hidden: _ctx.activeComponent != 'program'}, "btn btn-size-header-main btn-primary"]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramItemClick && _ctx.btnCreateNewProgramItemClick(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_4),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("Program.CreateNewButton" )), 1)
            ], 2),
            _createElementVNode("button", {
              id: "btnCreateNewEvaluationQuestionItem",
              class: _normalizeClass([{ hidden: _ctx.activeComponent != 'evaluationQuestions'}, "btn btn-size-header-large btn-primary"]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewEvaluationQuestionItemClick && _ctx.btnCreateNewEvaluationQuestionItemClick(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_5),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("EvaluationQuestions.Button.CreateNewQuestion")), 1)
            ], 2),
            _createElementVNode("button", {
              id: "btnCreateNewProgramQuestionItem",
              class: _normalizeClass([{ hidden: _ctx.activeComponent != 'sessionQuestions'}, "btn btn-size-header-large btn-primary"]),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramQuestionItemClick && _ctx.btnCreateNewProgramQuestionItemClick(...args)))
            }, [
              _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_6),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("SessionQuestions.Button.CreateNewQuestion")), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      id: "programitems",
      class: _normalizeClass(["tab-pane tab-flex-container", { hidden: _ctx.activeComponent != 'program'}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["new-toolbarContainer", {'tool-bar-height': _ctx.isTabView || _ctx.isDeviceExtraSmall}])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              id: "inputSelectAllItems",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectAllProgramItems) = $event)),
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAllProgramItems]
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("SelectAll")), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["menu right-side-tool-bar", {'tab-view': _ctx.isTabView}])
        }, [
          (_ctx.isHavingRTMPStreams && !_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "menu-item",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.GetRTMPDetails()))
              }, [
                _createElementVNode("img", {
                  class: "menu-item-icon",
                  src: _ctx.importIcon,
                  alt: "import"
                }, null, 8, _hoisted_10),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("Program.RTMPDetails")), 1)
              ]))
            : _createCommentVNode("", true),
          _cache[48] || (_cache[48] = _createTextVNode("   ")),
          _createElementVNode("div", {
            id: "btnCreateNewProgramTrackItem",
            class: "menu-item",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramTrackItemClick && _ctx.btnCreateNewProgramTrackItemClick(...args)))
          }, [
            _createElementVNode("img", {
              class: "menu-item-icon",
              src: _ctx.trackIcon,
              alt: "track"
            }, null, 8, _hoisted_12),
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("Program.TracksTab")), 1)
          ]),
          _cache[49] || (_cache[49] = _createTextVNode("   ")),
          _createElementVNode("div", {
            id: "btnCreateNewProgramTagItem",
            class: "menu-item",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramTagItemClick && _ctx.btnCreateNewProgramTagItemClick(...args)))
          }, [
            _createElementVNode("img", {
              class: "menu-item-icon",
              src: _ctx.tagIcon,
              alt: "tag"
            }, null, 8, _hoisted_14),
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("Program.TagsTab")), 1)
          ]),
          _cache[50] || (_cache[50] = _createTextVNode("   ")),
          (!_ctx.isMobileView)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                id: "btnImportPrograms",
                class: "menu-item",
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.btnImportProgramsClicked && _ctx.btnImportProgramsClicked(...args)))
              }, [
                _createElementVNode("img", {
                  class: "menu-item-icon",
                  src: _ctx.importIcon,
                  alt: "import"
                }, null, 8, _hoisted_16),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("Program.Import")), 1)
              ]))
            : _createCommentVNode("", true),
          _cache[51] || (_cache[51] = _createTextVNode("   "))
        ], 2)
      ], 2),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_ProgramListView, {
            "program-items": _ctx.viewModel.ProgramList,
            "select-all-items": _ctx.selectAllProgramItems,
            "applicationborder-color": _ctx.loaderBorderColor,
            "editing-program-item": _ctx.localEditedProgramItem,
            "track-items": _ctx.viewModel.ProgramTracks,
            "participant-groups": _ctx.viewModel.ParticipantGroups,
            onProgramItemsSelected: _cache[9] || (_cache[9] = ($event: any) => (_ctx.programItemsSelected($event))),
            onProgramItemEditOperation: _cache[10] || (_cache[10] = ($event: any) => (_ctx.programItemEditedOperation($event))),
            onCreateNewProgramClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.CreateNewProgramItem())),
            onChangeProgramItem: _cache[12] || (_cache[12] = ($event: any) => (_ctx.ChangeProramItem($event))),
            onUpdateProgramSorting: _cache[13] || (_cache[13] = ($event: any) => (_ctx.UpdateProgramSorting())),
            onProgramItemCancelOperation: _cache[14] || (_cache[14] = ($event: any) => (_ctx.programItemCancelOperation($event)))
          }, null, 8, ["program-items", "select-all-items", "applicationborder-color", "editing-program-item", "track-items", "participant-groups"])
        ]),
        _createElementVNode("div", {
          id: "dvMainProgramItems",
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != _ctx.SelectedProgram}])
        }, [
          (_ctx.selectedProgramItems.length === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_component_ProgramEditItemPane, {
                  ref: "EditProgramItem",
                  "selected-program-item": Object.assign({}, _ctx.selectedProgramItems[0]),
                  "is-enable-program-question": _ctx.enableProgramQuestion,
                  "available-tracks": _ctx.viewModel.ProgramTracks,
                  "available-tags": _ctx.viewModel.ProgramTags,
                  "available-durations": _ctx.viewModel.Durations,
                  "applicationborder-color": _ctx.loaderBorderColor,
                  "available-custom-category-folders": _ctx.viewModel.CustomCategoryFolders,
                  "application-id": _ctx.applicationId,
                  "application-instance-id": _ctx.applicationInstanceId,
                  "previously-selected-font-awesome-icon": _ctx.previouslySelectedFontAwesomeIcon,
                  "global-program-evaluation": _ctx.programEvaluationsLinkedToAllPrograms,
                  "participant-groups": _ctx.viewModel.ParticipantGroups,
                  "new-program-start-date": _ctx.GetNewProgramStartDate(),
                  onProgramItemCancelOperation: _cache[15] || (_cache[15] = ($event: any) => (_ctx.programItemCancelOperation($event))),
                  onProgramItemEditOperation: _cache[16] || (_cache[16] = ($event: any) => (_ctx.programItemEditedOperation($event))),
                  onProgramItemDeleteOperation: _cache[17] || (_cache[17] = ($event: any) => (_ctx.programItemDeletedOperation($event)))
                }, null, 8, ["selected-program-item", "is-enable-program-question", "available-tracks", "available-tags", "available-durations", "applicationborder-color", "available-custom-category-folders", "application-id", "application-instance-id", "previously-selected-font-awesome-icon", "global-program-evaluation", "participant-groups", "new-program-start-date"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'selectedBatchprogram'}])
        }, [
          (_ctx.selectedProgramItems.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_ProgramBatchEditPane, {
                  "selected-items": _ctx.selectedProgramItems,
                  "available-custom-category-folders": _ctx.viewModel.CustomCategoryFolders,
                  "track-items": _ctx.viewModel.ProgramTracks,
                  "available-custom-category-items": _ctx.viewModel.CustomCategoryItems,
                  "available-tags": _ctx.viewModel.ProgramTags
                }, null, 8, ["selected-items", "available-custom-category-folders", "track-items", "available-custom-category-items", "available-tags"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel import-edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent!='import'}])
        }, [
          (_ctx.activeSubComponent=='import')
            ? (_openBlock(), _createBlock(_component_ImportFile, {
                key: 0,
                "module-type": _ctx.moduleType,
                onCloseImport: _cache[18] || (_cache[18] = ($event: any) => (_ctx.ProgramImportCancelOperation()))
              }, null, 8, ["module-type"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          id: "programtracks",
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'tracks'}])
        }, [
          (_ctx.activeSubComponent=='tracks')
            ? (_openBlock(), _createBlock(_component_ProgramTrackListView, {
                key: 0,
                "track-items": _ctx.viewModel.ProgramTracks,
                "applicationborder-color": _ctx.loaderBorderColor,
                onProgramTrackCloseOperation: _cache[19] || (_cache[19] = ($event: any) => (_ctx.programTrackItemCancelOperation()))
              }, null, 8, ["track-items", "applicationborder-color"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          id: "programtags",
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'tags'}])
        }, [
          (_ctx.activeSubComponent=='tags')
            ? (_openBlock(), _createBlock(_component_ProgramTagListView, {
                key: 0,
                "tag-items": _ctx.viewModel.ProgramTags,
                "applicationborder-color": _ctx.loaderBorderColor,
                onProgramTagCloseOperation: _cache[20] || (_cache[20] = ($event: any) => (_ctx.programTagItemCancelOperation()))
              }, null, 8, ["tag-items", "applicationborder-color"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel no-item right-inner-flex-container", { hidden: _ctx.activeSubComponent != ''}])
        }, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("iframe", {
              class: "iframe-area",
              src: _ctx.GetIframeUrl()
            }, null, 8, _hoisted_23)
          ])
        ], 2)
      ])
    ], 2),
    _createElementVNode("div", {
      id: "evaluationQuestions",
      class: _normalizeClass(["tab-pane tab-flex-container", { hidden: _ctx.activeComponent != 'evaluationQuestions'}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["toolbarContainer inline-flex100", {'toolbarContainerMobile': _ctx.isDeviceExtraSmall}])
      }, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("label", _hoisted_25, [
            _withDirectives(_createElementVNode("input", {
              id: "inputSelectAllEvaluationQuestions",
              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.selectAllEvaluationQuestions) = $event)),
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAllEvaluationQuestions]
            ]),
            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("SelectAll" )), 1)
          ])
        ]),
        _cache[53] || (_cache[53] = _createElementVNode("div", { class: "noItemDisplay middle-containter" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["right-side-tool-bar", [_ctx.isMobileView ==true ? 'evaluations-mobile' : 'evaluations']])
        }, [
          _createElementVNode("button", {
            class: "btn btn-size-header-main btn-secondary dropdown-toggle",
            "data-toggle": "dropdown",
            disabled: _ctx.existingEvaluationQuestionsCount===0
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t("EvaluationQuestions.Button.ExportResults")) + " ", 1),
            _cache[52] || (_cache[52] = _createElementVNode("i", { class: "fa fa-angle-down manage-down-icon" }, null, -1))
          ], 8, _hoisted_27),
          _createElementVNode("ul", _hoisted_28, [
            _createElementVNode("li", {
              class: "manage-list-text",
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.OpenEvaluationQuestionResultsToEmailPopup()))
            }, [
              _createElementVNode("a", null, _toDisplayString(_ctx.$t("Export.ExportResultsToEmail")), 1)
            ]),
            _createElementVNode("li", {
              class: "manage-list-text",
              onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.ExportEvaluationQuestionResultsToExcel()))
            }, [
              _createElementVNode("a", null, _toDisplayString(_ctx.$t("Export.ExportResultsToExcel")), 1)
            ])
          ])
        ], 2)
      ], 2),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_EvaluationQuestionsListView, {
            "select-all-items": _ctx.selectAllEvaluationQuestions,
            "program-items": _ctx.viewModel.ProgramList,
            "editing-program-evaluation-question-item": _ctx.localEditedProgramEvaluationQuestionItem,
            onProgramEvaluationQuestionTypes: _cache[24] || (_cache[24] = ($event: any) => (_ctx.FillProgramEvaluationQuestiontypes($event))),
            onProgramEvaluationQuestionIsGlobalProgramEvaluation: _cache[25] || (_cache[25] = ($event: any) => (_ctx.FillIsGlobalProgramEvaluation($event))),
            onProgramEvaluationQuestionsSelected: _cache[26] || (_cache[26] = ($event: any) => (_ctx.evaluationQuestionsSelected($event))),
            onCreateNewQuestionClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.CreateNewEvaluationQuestionItem())),
            onProgramEvaluationQuestionCount: _cache[28] || (_cache[28] = ($event: any) => (_ctx.UpdateExistingEvaluationQuestionsCount($event))),
            onChangeEvaluationQuestionItem: _cache[29] || (_cache[29] = ($event: any) => (_ctx.ChangeEvaluationQuestionItem($event))),
            onUncheckSelectAllEvaluationQuestions: _cache[30] || (_cache[30] = ($event: any) => (_ctx.UpdateSelectAllCheckboxEvaluationQuestions($event)))
          }, null, 8, ["select-all-items", "program-items", "editing-program-evaluation-question-item"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel no-item right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'evaluationQuestionsLandingPage'}])
        }, [
          _createVNode(_component_EvaluationQuestionsLandingPage, {
            "program-evaluation-question-types": _ctx.programEvaluationQuestionTypes,
            onCreateNewQuestionClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.CreateEvaluationQuestionOfSelectedType($event)))
          }, null, 8, ["program-evaluation-question-types"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'evaluationQuestionsCreateNewQuestion'}])
        }, [
          (_ctx.selectedEvaluationQuestions.length===1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _createVNode(_component_EvaluationQuestionsEditItemPane, {
                  ref: "EditEvaluationQuestionItem",
                  "selected-evaluation-question": _ctx.selectedEvaluationQuestions[0],
                  "program-evaluation-question-types": _ctx.programEvaluationQuestionTypes,
                  "program-items": _ctx.viewModel.ProgramList,
                  "global-program-evaluation": _ctx.programEvaluationsLinkedToAllPrograms,
                  onProgramEvaluationQuestionItemCancelOperation: _cache[32] || (_cache[32] = ($event: any) => (_ctx.ProgramEvaluationQuestionItemCancelOperation($event)))
                }, null, 8, ["selected-evaluation-question", "program-evaluation-question-types", "program-items", "global-program-evaluation"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'evaluationsBatchEdit'}])
        }, [
          (_ctx.selectedEvaluationQuestions.length>1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                _createVNode(_component_EvaluationQuestionBatchPane, {
                  "selected-items": _ctx.selectedEvaluationQuestions,
                  "program-items": _ctx.viewModel.ProgramList,
                  "global-program-evaluation": _ctx.programEvaluationsLinkedToAllPrograms
                }, null, 8, ["selected-items", "program-items", "global-program-evaluation"])
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      (_ctx.evaluationQuestionExportToEmailPopupVisible)
        ? (_openBlock(), _createBlock(_component_EvaluationQuestionExportResultsToEmailModalPopup, {
            key: 0,
            onConfirmClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.ConfirmEvaluationExportToEmailPopupClick($event)))
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      id: "sessionQuestions",
      class: _normalizeClass(["tab-pane tab-flex-container", { hidden: _ctx.activeComponent != 'sessionQuestions'}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["toolbarContainer inline-flex100", {'toolbarContainerMobile': _ctx.isDeviceExtraSmall}])
      }, [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("label", _hoisted_34, [
            _withDirectives(_createElementVNode("input", {
              id: "inputSelectAllSessionQuestions",
              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.selectAllSessionQuestions) = $event)),
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAllSessionQuestions]
            ]),
            _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("SelectAll" )), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["right-side-tool-bar", _ctx.GetProgramQuestionsClassName()])
        }, [
          _createElementVNode("button", {
            class: "btn btn-size-header-main btn-secondary",
            disabled: _ctx.existingSessionQuestionsCount===0,
            onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.ExportSessionQuestionResultsToExcel()))
          }, _toDisplayString(_ctx.$t("SessionQuestions.Button.ExportResults")), 9, _hoisted_36),
          _cache[54] || (_cache[54] = _createTextVNode("  "))
        ], 2)
      ], 2),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createVNode(_component_SessionQuestionsListView, {
            "select-all-items": _ctx.selectAllSessionQuestions,
            "existing-session-questions-count": _ctx.existingSessionQuestionsCount,
            "editing-program-session-question-item": _ctx.localEditedProgramSessionQuestionItem,
            onProgramQuestionTypes: _cache[36] || (_cache[36] = ($event: any) => (_ctx.FillProgramQuestiontypes($event))),
            onProgramSessionQuestionsSelected: _cache[37] || (_cache[37] = ($event: any) => (_ctx.sessionQuestionsSelected($event))),
            onCreateNewQuestionClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.CreateNewProgramQuestionItem())),
            onProgramSessionQuestionCount: _cache[39] || (_cache[39] = ($event: any) => (_ctx.UpdateExistingSessionQuestionsCount($event))),
            onChangeSessionQuestionItem: _cache[40] || (_cache[40] = ($event: any) => (_ctx.ChangeSessionQuestionItem($event))),
            onUncheckSelectAllSessionQuestions: _cache[41] || (_cache[41] = ($event: any) => (_ctx.UpdateSelectAllCheckboxSessionQuestions($event)))
          }, null, 8, ["select-all-items", "existing-session-questions-count", "editing-program-session-question-item"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel no-item right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'sessionQuestionsLandingPage'}])
        }, [
          _createVNode(_component_SessionQuestionsLandingPage, {
            "program-question-types": _ctx.programQuestionTypes,
            onCreateNewQuestionClick: _cache[42] || (_cache[42] = ($event: any) => (_ctx.CreateSessionQuestionOfSelectedType($event)))
          }, null, 8, ["program-question-types"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'sessionQuestionsCreateNewQuestion'}])
        }, [
          (_ctx.selectedSessionQuestions.length===1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createVNode(_component_SessionQuestionsEditItemPane, {
                  ref: "EditSessionQuestionItem",
                  "selected-session-question": _ctx.selectedSessionQuestions[0],
                  "program-question-types": _ctx.programQuestionTypes,
                  "program-items": _ctx.viewModel.ProgramList,
                  onProgramSessionQuestionItemCancelOperation: _cache[43] || (_cache[43] = ($event: any) => (_ctx.ProgramSessionQuestionItemCancelOperation($event)))
                }, null, 8, ["selected-session-question", "program-question-types", "program-items"])
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["edit-panel right-inner-flex-container", { hidden: _ctx.activeSubComponent != 'sessionQuestionsBatchEdit'}])
        }, [
          (_ctx.selectedSessionQuestions.length>1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _createVNode(_component_SessionQuestionBatchPane, {
                  "selected-items": _ctx.selectedSessionQuestions,
                  "program-items": _ctx.viewModel.ProgramList,
                  "show-loading": _ctx.showLoading,
                  "loader-border-color": _ctx.loaderBorderColor
                }, null, 8, ["selected-items", "program-items", "show-loading", "loader-border-color"])
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ], 2),
    (_ctx.isTabView || _ctx.isMobileView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
          _createElementVNode("button", {
            id: "btnCreateNewProgramItem",
            class: _normalizeClass([{ hidden: _ctx.activeComponent != 'program'}, "btn btn-size-header-main btn-primary create-new-btn"]),
            onClick: _cache[44] || (_cache[44] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramItemClick && _ctx.btnCreateNewProgramItemClick(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_42),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Program.CreateNewButton" )), 1)
          ], 2),
          _createElementVNode("button", {
            id: "btnCreateNewEvaluationQuestionItem",
            class: _normalizeClass([{ hidden: _ctx.activeComponent != 'evaluationQuestions'}, "btn btn-size-header-large btn-primary create-new-btn"]),
            onClick: _cache[45] || (_cache[45] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewEvaluationQuestionItemClick && _ctx.btnCreateNewEvaluationQuestionItemClick(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_43),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("EvaluationQuestions.Button.CreateNewQuestion")), 1)
          ], 2),
          _createElementVNode("button", {
            id: "btnCreateNewProgramQuestionItem",
            class: _normalizeClass([{ hidden: _ctx.activeComponent != 'sessionQuestions'}, "btn btn-size-header-large btn-primary create-new-btn"]),
            onClick: _cache[46] || (_cache[46] = 
//@ts-ignore
(...args) => (_ctx.btnCreateNewProgramQuestionItemClick && _ctx.btnCreateNewProgramQuestionItemClick(...args)))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_44),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("SessionQuestions.Button.CreateNewQuestion")), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}