import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "description-heading" }
const _hoisted_2 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.localActionButtonData.Heading), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.localActionButtonData.Description), 1),
    _createElementVNode("button", {
      class: "btn btn-size-header-main action-button btn-secondary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.EmitActionType(_ctx.localActionButtonData.ButtonAction)))
    }, _toDisplayString(_ctx.localActionButtonData.ButtonText), 1)
  ]))
}