import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ajax-loader grid-listing"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-heading primary-heading" }
const _hoisted_5 = { class: "margin-top-30" }
const _hoisted_6 = { class: "div-individual-responses margin-top-15 border-radius-10" }
const _hoisted_7 = { class: "individual-response-table" }
const _hoisted_8 = { class: "table-header" }
const _hoisted_9 = {
  class: "table-head",
  scope: "col"
}
const _hoisted_10 = {
  scope: "col",
  class: "table-head"
}
const _hoisted_11 = {
  scope: "col",
  class: "table-head"
}
const _hoisted_12 = {
  scope: "col",
  class: "table-head"
}
const _hoisted_13 = ["title"]
const _hoisted_14 = { class: "data-limitter" }
const _hoisted_15 = ["title"]
const _hoisted_16 = { class: "data-limitter" }
const _hoisted_17 = ["title"]
const _hoisted_18 = { class: "data-limitter" }
const _hoisted_19 = ["title"]
const _hoisted_20 = { class: "data-limitter" }
const _hoisted_21 = {
  key: 0,
  class: "empty-table-row"
}
const _hoisted_22 = {
  class: "empty-table-text",
  colspan: "4"
}
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  colspan: "4",
  class: "paginate-td"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginate = _resolveComponent("Paginate")!

  return (_ctx.isDataLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "img-responsive ajax-loader-img",
          src: _ctx.loadingImage,
          alt: "Wait"
        }, null, 8, _hoisted_2)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass({'participant-detail-container': _ctx.isMobileView})
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Close()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["width70", { 'margin-left-20': _ctx.isMobileView}])
          }, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("SessionCheckIn.CheckedInParticipants")), 1)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.GetDescription()), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _createElementVNode("thead", _hoisted_8, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("Name")), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t("Email")), 1),
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t("Company")), 1),
                _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t("SessionCheckIn.CheckInTime")), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localCheckedInParticipantData, (data, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", {
                    class: "table-data",
                    title: _ctx.GetFullName(data.FirstName, data.LastName)
                  }, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.GetTableData(_ctx.GetFullName(data.FirstName, data.LastName))), 1)
                  ], 8, _hoisted_13),
                  _createElementVNode("td", {
                    class: "table-data",
                    title: data.Email
                  }, [
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.GetTableData(data.Email)), 1)
                  ], 8, _hoisted_15),
                  _createElementVNode("td", {
                    class: "table-data",
                    title: data.Company
                  }, [
                    _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.GetTableData(data.Company)), 1)
                  ], 8, _hoisted_17),
                  _createElementVNode("td", {
                    class: "table-data",
                    title: data.CheckedInUtc
                  }, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.GetTableData(_ctx.FormatDateTime(data.CheckedInUtc))), 1)
                  ], 8, _hoisted_19)
                ]))
              }), 128)),
              (_ctx.checkedInParticipantData.length === 0)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                    _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.$t("NoDataAvailableYet")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                    _createElementVNode("td", _hoisted_24, [
                      _createVNode(_component_Paginate, {
                        modelValue: _ctx.page,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
                        "page-count": _ctx.pageCount,
                        "page-range": _ctx.paginationPageRange,
                        "margin-pages": _ctx.paginationPageMargin,
                        "click-handler": _ctx.clickCallback,
                        "prev-text": _ctx.pagiantionPreviewText,
                        "next-text": _ctx.pagiantionNextText,
                        "container-class": 'pagination',
                        "page-class": 'page-item',
                        "page-link-class": 'page-link-item',
                        "prev-link-class": 'preview-link',
                        "next-link-class": 'next-link'
                      }, null, 8, ["modelValue", "page-count", "page-range", "margin-pages", "click-handler", "prev-text", "next-text"])
                    ])
                  ]))
            ])
          ])
        ])
      ], 2))
}