import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 1,
  class: "track-edit-section"
}
const _hoisted_3 = { class: "item-position-from-image valign-parent50 display-flex align-items-center width-max-content" }
const _hoisted_4 = {
  key: 0,
  class: "margin-auto"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "valign" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  id: "programTrackItemEditPane",
  class: "margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ProgramTrackItemEditPane = _resolveComponent("ProgramTrackItemEditPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Program.DeleteTrack'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localTrackItem && !_ctx.showEditTrack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isImage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: _ctx.GetImageUrl(_ctx.localTrackItem.IconUrl),
                    class: "imageSquare"
                  }, null, 8, _hoisted_5)
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  style: _normalizeStyle(_ctx.GetColor()),
                  class: "colorPicker"
                }, null, 4)),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.localTrackItem.Name), 1)
          ]),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getRightDivStyle(_ctx.isMobileView)),
            class: "right-container-btn"
          }, [
            (!_ctx.isMobileView)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: _normalizeClass(["btn btn-secondary valign", [_ctx.isMobileView ==true ? '' : 'btn-size-default']]),
                  style: _normalizeStyle(_ctx.getEditButtonstyle(_ctx.isMobileView)),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectItem(_ctx.localTrackItem)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Edit")), 1)
                ], 6))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "btn btn-delete valign",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectItem(_ctx.localTrackItem)))
                }, [
                  _createElementVNode("img", { src: _ctx.editIcon }, null, 8, _hoisted_7)
                ])),
            _createElementVNode("div", {
              class: "btn btn-delete valign",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.DeleteSelectedTrack(_ctx.localTrackItem)))
            }, [
              _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_8)
            ])
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showEditTrack)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_ProgramTrackItemEditPane, {
            "selected-track-item": _ctx.localTrackItem,
            "applicationborder-color": _ctx.loaderBorderColor,
            onProgramTrackItemEditImageOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.programTrackItemImageEditedOperation($event))),
            onProgramTrackItemCancelOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.programTrackItemCancelOperation($event))),
            onProgramTrackItemEditOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.programTrackItemEditedOperation($event))),
            onProgramTrackItemImageUploadOperation: _cache[7] || (_cache[7] = ($event: any) => (_ctx.programTrackItemImageUploadOperation($event)))
          }, null, 8, ["selected-track-item", "applicationborder-color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}