import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = { class: "inline-flex100 justify-content-space-between align-items-center margin-bottom-10" }
const _hoisted_3 = { class: "width55" }
const _hoisted_4 = { class: "font-size-20 font-weight-bold no-margin" }
const _hoisted_5 = { class: "hyperLink inline-flex" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "add-information-text" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "ajax-loader"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "description"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 1,
  class: "margin-top-10"
}
const _hoisted_17 = { class: "inline-flex align-items-center" }
const _hoisted_18 = { class: "img-select round-icon" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "selectInformationStyle" }
const _hoisted_21 = { class: "valign" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t("Program.LinkedInformation")), 1)
      ]),
      (!_ctx.localLinkedInformationLoading && _ctx.selectedProgramId>0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "add-information-div action-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnAddInformation()))
          }, [
            _createElementVNode("a", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.addIconImage,
                alt: "Add icon"
              }, null, 8, _hoisted_6),
              _createElementVNode("span", _hoisted_7, [
                (_ctx.localLinkedInformation==null || _ctx.localLinkedInformation.length==0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("Program.Information.Add")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("Program.Information.Edit")), 1))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.localLinkedInformationLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("img", {
            class: "img-responsive ajax-loader-img",
            src: _ctx.loadingImage,
            alt: "Wait"
          }, null, 8, _hoisted_11)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_ctx.localLinkedInformation==null || _ctx.localLinkedInformation.length==0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                (_ctx.selectedProgramId==0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("Program.LinkedInformation.CreateNewProgramItemMessage")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("Program.LinkedInformation.NoItemMessage")), 1))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localLinkedInformation, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.CustomCategoryId,
                    class: "content-under-header"
                  }, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("img", {
                          class: "iconImg",
                          src: _ctx.GetImageUrl(item)
                        }, null, 8, _hoisted_19)
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(item.Title), 1)
                      ])
                    ])
                  ]))
                }), 128))
              ]))
        ]))
  ]))
}