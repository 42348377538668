import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vShow as _vShow, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = {
  id: "programEditMode",
  class: "scroll-top editPane"
}
const _hoisted_2 = {
  key: 1,
  class: "right-container-margin"
}
const _hoisted_3 = { class: "inline-flex100 align-items-center" }
const _hoisted_4 = { class: "main-title-width" }
const _hoisted_5 = { class: "main-heading primary-heading" }
const _hoisted_6 = { class: "inline-flex" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "padding-top-20" }
const _hoisted_9 = {
  key: 0,
  class: "alert alert-success margin-top-10"
}
const _hoisted_10 = { class: "width55" }
const _hoisted_11 = { class: "new-section-heading" }
const _hoisted_12 = { class: "inline-flex100" }
const _hoisted_13 = { id: "logo-div" }
const _hoisted_14 = ["title"]
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  id: "content-div",
  class: "width100 margin-left-10"
}
const _hoisted_17 = {
  id: "textarea-div",
  class: "margin-top-10 width100"
}
const _hoisted_18 = {
  for: "txtAreaProgramTitle",
  class: "textarea-label"
}
const _hoisted_19 = { class: "color-red" }
const _hoisted_20 = { class: "margin-top-20" }
const _hoisted_21 = {
  id: "duration-date",
  class: "date-time-position"
}
const _hoisted_22 = { class: "date-position" }
const _hoisted_23 = {
  class: "font-size-16",
  for: "Start"
}
const _hoisted_24 = { class: "color-red" }
const _hoisted_25 = { class: "program-datepicker" }
const _hoisted_26 = { class: "duration-width" }
const _hoisted_27 = {
  class: "font-size-16",
  for: "Duration"
}
const _hoisted_28 = { class: "color-red" }
const _hoisted_29 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_30 = ["value"]
const _hoisted_31 = { class: "margin-left-10 description font-size-16" }
const _hoisted_32 = ["value"]
const _hoisted_33 = { class: "margin-left-10 description" }
const _hoisted_34 = { class: "hide-duration" }
const _hoisted_35 = { class: "description" }
const _hoisted_36 = {
  for: "Description",
  class: "margin-top-15 font-size-16"
}
const _hoisted_37 = {
  for: "Tags",
  class: "new-top-margin-in-fields font-size-16"
}
const _hoisted_38 = {
  for: "Track",
  class: "new-top-margin-in-fields font-size-16"
}
const _hoisted_39 = ["value"]
const _hoisted_40 = ["value"]
const _hoisted_41 = {
  for: "Room location",
  class: "new-top-margin-in-fields font-size-16"
}
const _hoisted_42 = {
  key: 0,
  class: "ListOfParticipantGroups margin-top-20"
}
const _hoisted_43 = { class: "margin-top-25" }
const _hoisted_44 = {
  key: 1,
  id: "programVirtualMeetingDiv"
}
const _hoisted_45 = { key: 2 }
const _hoisted_46 = {
  key: 3,
  class: "margin-35-0"
}
const _hoisted_47 = { id: "programLinkedInformationDiv" }
const _hoisted_48 = {
  key: 2,
  class: "right-container-margin"
}
const _hoisted_49 = {
  key: 3,
  class: "right-container-margin"
}
const _hoisted_50 = {
  key: 4,
  class: "right-container-margin"
}
const _hoisted_51 = {
  key: 5,
  class: "right-container-margin"
}
const _hoisted_52 = {
  key: 6,
  class: "right-container-margin"
}
const _hoisted_53 = {
  key: 7,
  class: "right-container-margin"
}
const _hoisted_54 = {
  key: 0,
  class: "right-container-margin"
}
const _hoisted_55 = {
  key: 8,
  class: "right-container-margin"
}
const _hoisted_56 = {
  key: 9,
  class: "right-container-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_Editor = _resolveComponent("Editor")!
  const _component_InpuTag = _resolveComponent("InpuTag")!
  const _component_ListOfParticipantGroups = _resolveComponent("ListOfParticipantGroups")!
  const _component_EventWebPageVisibiltity = _resolveComponent("EventWebPageVisibiltity")!
  const _component_SessionCheckIn = _resolveComponent("SessionCheckIn")!
  const _component_MapPicker = _resolveComponent("MapPicker")!
  const _component_VirtualMeetingDetails = _resolveComponent("VirtualMeetingDetails")!
  const _component_ProgramSessionQuestionsListView = _resolveComponent("ProgramSessionQuestionsListView")!
  const _component_ProgramLinkedInformationListView = _resolveComponent("ProgramLinkedInformationListView")!
  const _component_MultimediaListView = _resolveComponent("MultimediaListView")!
  const _component_ProgramEvaluationQuestionsListView = _resolveComponent("ProgramEvaluationQuestionsListView")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!
  const _component_AddInformationWithEntity = _resolveComponent("AddInformationWithEntity")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_MuxVideo = _resolveComponent("MuxVideo")!
  const _component_PreRecordedMeetingHighcharts = _resolveComponent("PreRecordedMeetingHighcharts")!
  const _component_VirtualMeetingSessionAnalytics = _resolveComponent("VirtualMeetingSessionAnalytics")!
  const _component_VirtualMeetingCombineAnalytics = _resolveComponent("VirtualMeetingCombineAnalytics")!
  const _component_CheckedInParticipantDetails = _resolveComponent("CheckedInParticipantDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Delete'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button", "show-cancel-button", "cancel-button-text", "confirm-button-text"]))
      : _createCommentVNode("", true),
    (_ctx.ShowProgramEditDetailView())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                id: "btnCancel",
                class: "btn btn-secondary btn-size-default",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("CancelButton")), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("Program.ProgramItem")), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                id: "btnSave",
                class: "btn btn-primary btn-size-default",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("SaveButton")), 1),
              (!_ctx.localProgramItem.Id==0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "btn btn-delete",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ClickDeleteProgram()))
                  }, [
                    _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_7)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.visibleValidationSummary)
              ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                  key: 0,
                  "error-fields": _ctx.validationErrorFields,
                  onCloseClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                }, null, 8, ["error-fields"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.messages.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
                  return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.$t("Program.GeneralDetails")), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                id: "divIconImage",
                class: "img-select margin-right-10",
                title: _ctx.GetTooltipMessage(),
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clickOnImage(true)))
              }, [
                _createElementVNode("img", {
                  id: "imgIconImage round-icon",
                  class: "iconImg top-margin-in-fields round-image",
                  src: _ctx.GetImageUrl()
                }, null, 8, _hoisted_15)
              ], 8, _hoisted_14)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, [
                  _createTextVNode(_toDisplayString(_ctx.$t("Title")), 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("Asteric")), 1)
                ]),
                _withDirectives(_createElementVNode("textarea", {
                  id: "txtAreaProgramTitle",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.v$.localProgramItem.Title.$model) = $event)),
                  class: _normalizeClass(["form-control textarea-title textarea-size", _ctx.status(_ctx.v$.localProgramItem.Title)]),
                  maxlength: "120"
                }, null, 2), [
                  [
                    _vModelText,
                    _ctx.v$.localProgramItem.Title.$model,
                    void 0,
                    { trim: true }
                  ]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", _hoisted_23, [
                  _createTextVNode(_toDisplayString(_ctx.$t("StartDate")) + " ", 1),
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("Asteric")), 1)
                ]),
                (_ctx.isTimeZoneFeatureActive())
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "fa fa-info-circle time-zone-tooltip",
                      "aria-hidden": "true",
                      onMouseover: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getInformationString(true))),
                      onMouseleave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.getInformationString(false)))
                    }, null, 32))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("div", { class: "timezone-information" }, _toDisplayString(_ctx.getTimeZoneTitle()), 513), [
                  [_vShow, _ctx.isTimezoneInfoVisible]
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_Datepicker, {
                    date: _ctx.GetProgramStartDate(),
                    "full-day-time-format": _ctx.CheckTimeFormat(),
                    "id-value": _ctx.ProgramModule,
                    onSelectedDate: _ctx.SetBrowserDateValue
                  }, null, 8, ["date", "full-day-time-format", "id-value", "onSelectedDate"])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", _hoisted_27, [
                  _createTextVNode(_toDisplayString(_ctx.$t("Program.Input.Duration")) + " ", 1),
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("Asteric")), 1)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _withDirectives(_createElementVNode("select", {
                    id: "selDurationHours",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localProgramItem.Duration.Hours) = $event)),
                    class: "form-control select-duration selectBox",
                    onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onHoursChange($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.durationHours, (duration) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: duration,
                        value: duration
                      }, _toDisplayString(duration), 9, _hoisted_30))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.localProgramItem.Duration.Hours]
                  ]),
                  _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('Hours')), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "selDurationMinutes",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localProgramItem.Duration.Minutes) = $event)),
                    class: "form-control select-duration margin-left-10 selectBox",
                    onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onMinutesChange($event.target.value)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.durationMinutes, (duration) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: duration,
                        value: duration
                      }, _toDisplayString(duration), 9, _hoisted_32))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.localProgramItem.Duration.Minutes]
                  ]),
                  _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('Minutes')), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("label", _hoisted_35, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localProgramItem.HideEndTimeDuration) = $event)),
                  type: "checkbox"
                }, null, 512), [
                  [_vModelCheckbox, _ctx.localProgramItem.HideEndTimeDuration]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("Program.HideEndTimeDuration")), 1)
              ])
            ]),
            _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("Description")), 1),
            _createVNode(_component_Editor, {
              description: _ctx.localProgramItem.Description,
              onUpdatedDescription: _ctx.SetDescriptionValue
            }, null, 8, ["description", "onUpdatedDescription"]),
            _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t("Program.TagsTab")), 1),
            _createVNode(_component_InpuTag, {
              "selected-tags": _ctx.selectedTags,
              "existing-tags": _ctx.existingTags,
              onSelectedTags: _ctx.SetTagNamesValue
            }, null, 8, ["selected-tags", "existing-tags", "onSelectedTags"]),
            _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t("Program.Track")), 1),
            _withDirectives(_createElementVNode("select", {
              id: "selTrack",
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localProgramItem.Track) = $event)),
              class: "form-control selectBox"
            }, [
              _createElementVNode("option", { value: _ctx.defaultTrack }, _toDisplayString(_ctx.$t("Program.Input.NoTrackAssignment")), 9, _hoisted_39),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTracks, (track) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: track.Id,
                  value: track
                }, _toDisplayString(track.Name), 9, _hoisted_40))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.localProgramItem.Track]
            ]),
            _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t("Program.Location")), 1),
            _withDirectives(_createElementVNode("input", {
              id: "inputRoomLocation",
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localProgramItem.Location) = $event)),
              type: "text",
              class: "form-control"
            }, null, 512), [
              [_vModelText, _ctx.localProgramItem.Location]
            ]),
            (_ctx.isEnableLinkProgramItemsToGroup)
              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                  _createVNode(_component_ListOfParticipantGroups, {
                    "participant-groups": _ctx.participantGroups,
                    "sub-heading": _ctx.subHeadingString,
                    "display-group-info-in-app": _ctx.localProgramItem.DisplayGroupInfoInApp,
                    onSendListOfParticipantGroupsIneditpane: _ctx.SetListParticipantGroups,
                    onSendListOfParticipantGroupsInLocalItem: _ctx.setListInLocalItem,
                    onSetGroupVisibilityValue: _cache[16] || (_cache[16] = ($event: any) => (_ctx.SetGroupVisibilityValue($event)))
                  }, null, 8, ["participant-groups", "sub-heading", "display-group-info-in-app", "onSendListOfParticipantGroupsIneditpane", "onSendListOfParticipantGroupsInLocalItem"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_EventWebPageVisibiltity, {
              "is-checkbox-visible": _ctx.IsEventWebpagePFEnabled(),
              "module-name": _ctx.$t('Program.ProgramTab').toLowerCase(),
              "is-checkbox-checked": _ctx.localProgramItem.HideFromEventWebpage,
              onSetCheckboxValue: _cache[17] || (_cache[17] = ($event: any) => (_ctx.SetEventWebpageCheckboxValue($event)))
            }, null, 8, ["is-checkbox-visible", "module-name", "is-checkbox-checked"]),
            _createVNode(_component_SessionCheckIn, {
              "session-check-in-data": _ctx.sessionCheckInData,
              onShowCheckedInParticipantDetails: _cache[18] || (_cache[18] = ($event: any) => (_ctx.GetCheckedInParticipantData($event))),
              onChangeInSessionCheckIn: _cache[19] || (_cache[19] = ($event: any) => (_ctx.SaveOrUpdateSessionCheckInData($event)))
            }, null, 8, ["session-check-in-data"]),
            _cache[30] || (_cache[30] = _createElementVNode("hr", { class: "margin-35-0" }, null, -1)),
            _createElementVNode("div", _hoisted_43, [
              _createVNode(_component_MapPicker, {
                "selected-item": _ctx.localProgramItem,
                "show-heading": true
              }, null, 8, ["selected-item"])
            ]),
            _cache[31] || (_cache[31] = _createElementVNode("hr", { class: "margin-35-0" }, null, -1)),
            (_ctx.isVirtualMeetingDetailsFeatureActive())
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                  _createVNode(_component_VirtualMeetingDetails, {
                    "selected-item": _ctx.localProgramItem,
                    "selected-vod-base": _ctx.selectedVodRenderer,
                    onSelectedMeetingType: _cache[20] || (_cache[20] = ($event: any) => (_ctx.SetVodRenderer($event)))
                  }, null, 8, ["selected-item", "selected-vod-base"]),
                  _cache[29] || (_cache[29] = _createElementVNode("hr", { class: "margin-40-0-35" }, null, -1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.localIsEnableProgramQuestion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                  _createVNode(_component_ProgramSessionQuestionsListView, {
                    "session-questions": _ctx.localProgramItem.SessionQuestions,
                    "program-question-link-expiration-date": _ctx.localProgramItem.ProgramQuestionLinkExpirationDate,
                    "program-question-published-link": _ctx.localProgramItem.ProgramQuestionPublishedLink,
                    "selected-program-id": _ctx.localProgramItem.Id,
                    onSessionQuestionDelinkProgramNotification: _cache[21] || (_cache[21] = ($event: any) => (_ctx.SendNotificationRequestToQueueForProgramDetails($event)))
                  }, null, 8, ["session-questions", "program-question-link-expiration-date", "program-question-published-link", "selected-program-id"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.localIsEnableProgramQuestion)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_46))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_47, [
              _createVNode(_component_ProgramLinkedInformationListView, {
                "linked-information": _ctx.localProgramItem.LinkedInformation,
                "selected-program-id": _ctx.localProgramItem.Id,
                onShowAttachInformation: _cache[22] || (_cache[22] = ($event: any) => (_ctx.showAttachInformation($event)))
              }, null, 8, ["linked-information", "selected-program-id"])
            ]),
            _cache[32] || (_cache[32] = _createElementVNode("hr", { class: "margin-35-0" }, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_MultimediaListView, {
                "program-medias": _ctx.localProgramItem.ProgramMedias,
                "selected-program-id": _ctx.localProgramItem.Id,
                onProgramMultimediaItemShowImageComponentOperation: _cache[23] || (_cache[23] = ($event: any) => (_ctx.programMultimediaItemShowImageUploadComponent($event)))
              }, null, 8, ["program-medias", "selected-program-id"])
            ]),
            _cache[33] || (_cache[33] = _createElementVNode("hr", { class: "margin-35-0" }, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_ProgramEvaluationQuestionsListView, {
                "evaluation-questions": _ctx.localProgramItem.EvaluationQuestions,
                "selected-program-id": _ctx.localProgramItem.Id,
                "global-program-evaluation": _ctx.globalProgramEvaluation,
                onEvaluationQuestionDelinkProgramNotification: _cache[24] || (_cache[24] = ($event: any) => (_ctx.SendNotificationRequestToQueueForProgramDetails($event)))
              }, null, 8, ["evaluation-questions", "selected-program-id", "global-program-evaluation"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowImageProcessSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
          _createVNode(_component_ImageProcess, {
            "featured-image-full-src": _ctx.GetfeaturedimageFullUrl(),
            "featuredimage-src": _ctx.GetFeaturedImageUrl(),
            "featured-image-width": _ctx.localProgramItem.FeaturedImageWidth,
            "featured-image-height": _ctx.localProgramItem.FeaturedImageHeight,
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageFullUrl(),
            "show-featured-image": _ctx.isEnableFeaturedImage,
            "heading-title": _ctx.isEnableFeaturedImage== true ? _ctx.$t('ImageProcess.headingTitle') : _ctx.$t('ImageProcess.iconImage'),
            "has-featured-image": _ctx.CheckFeaturedImage(),
            "left-position": _ctx.localProgramItem.LeftPosition,
            "top-position": _ctx.localProgramItem.TopPosition,
            "module-name": _ctx.ProgramModule,
            "sub-module-name": _ctx.subModuleName,
            "old-selected-font-awesome-icon": _ctx.previouslySelectedFontAwesomeIcon,
            "show-title-below-featured-image": _ctx.localProgramItem.ShowFeatureImageBelowTitle,
            onSetImageIconUrl: _ctx.SetImageIconUrl,
            onSelectBack: _ctx.clickOnImage,
            onSetFeaturedImageIconUrl: _ctx.SetFeaturedImageIconUrl,
            onShowTitleBelowFeaturedImage: _ctx.ShowProgramtitleBelowFeaturedImage
          }, null, 8, ["featured-image-full-src", "featuredimage-src", "featured-image-width", "featured-image-height", "image-src", "image-full-src", "show-featured-image", "heading-title", "has-featured-image", "left-position", "top-position", "module-name", "sub-module-name", "old-selected-font-awesome-icon", "show-title-below-featured-image", "onSetImageIconUrl", "onSelectBack", "onSetFeaturedImageIconUrl", "onShowTitleBelowFeaturedImage"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowAddInformationSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
          _createVNode(_component_AddInformationWithEntity, {
            "selected-entity-id": _ctx.localProgramItem.Id,
            "all-informations": _ctx.localAllInformations,
            "custom-category-folders": _ctx.localCustomCategoryFolders,
            "is-bulk-operation": false,
            "entity-type": _ctx.entityType,
            onSelectBack: _cache[25] || (_cache[25] = ($event: any) => (_ctx.showAttachInformation($event))),
            onLinkedCategoryAddProgramNotification: _cache[26] || (_cache[26] = ($event: any) => (_ctx.SendNotificationRequestToQueueForProgramDetails($event)))
          }, null, 8, ["selected-entity-id", "all-informations", "custom-category-folders", "entity-type"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowFileUploadSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
          _createVNode(_component_FileUpload, {
            "module-type": _ctx.ProgramModule,
            "sub-module-name": _ctx.subModuleName,
            "heading-title": _ctx.GetMultimediaFileUploadHeadingTitle(),
            "image-src": _ctx.GetMultimediaImageUrl(),
            "image-full-src": _ctx.GetMultimediaImageFullUrl(),
            "accepted-file-types": _ctx.GetAcceptedFileTypes(),
            "heading-sub-title": _ctx.GetMultimediaFileUploadSubHeadingTitle(),
            "file-extensions": _ctx.GetFileExtensions(),
            validationtype: _ctx.GetValidationType(),
            "icon-image-size-width": _ctx.GetIconImageSize(),
            "icon-image-size-height": _ctx.GetIconImageSize(),
            "validation-message": _ctx.GetValidationMessage(),
            type: _ctx.GetMultimediaImageType(),
            onSelectBack: _ctx.programMultimediaItemFileUploadCompleted,
            onSetImageIconUrl: _ctx.SetMultimediaIconUrl,
            onPreviousIconUrl: _ctx.GetPreviousIconUrl
          }, null, 8, ["module-type", "sub-module-name", "heading-title", "image-src", "image-full-src", "accepted-file-types", "heading-sub-title", "file-extensions", "validationtype", "icon-image-size-width", "icon-image-size-height", "validation-message", "type", "onSelectBack", "onSetImageIconUrl", "onPreviousIconUrl"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowAddVideoSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
          _createVNode(_component_MuxVideo, {
            "selected-vod-base": _ctx.selectedVodRenderer,
            onSetVodDetails: _cache[27] || (_cache[27] = ($event: any) => (_ctx.SetVodDetailId($event)))
          }, null, 8, ["selected-vod-base"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowPreRecordedVideoChartSection())
      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
          _createVNode(_component_PreRecordedMeetingHighcharts, {
            "video-detail": _ctx.GetVodDetailValues(),
            "video-on-demand-metrics": _ctx.localProgramItem.VodDetail.VodVideoAnalytics,
            "stream-type": _ctx.localProgramItem.VodDetail.VodStreamingType
          }, null, 8, ["video-detail", "video-on-demand-metrics", "stream-type"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowVirtualMeetingSessionAnalytics())
      ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
          (_ctx.vonageAnalyticslocal!=null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                _createVNode(_component_VirtualMeetingSessionAnalytics, {
                  "vonage-analytics-for-graph": _ctx.vonageAnalyticslocal,
                  "selected-program-id": _ctx.localProgramItem.Id,
                  "module-type": _ctx.selectedProgramItem.Moduletype
                }, null, 8, ["vonage-analytics-for-graph", "selected-program-id", "module-type"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowMeetAppLiveRecordingSessionAnalytics())
      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
          _createVNode(_component_VirtualMeetingCombineAnalytics, {
            "vonage-analytics-for-graph": _ctx.vonageAnalyticslocal,
            "selected-program-id": _ctx.localProgramItem.Id,
            "video-detail": _ctx.GetVodDetailValues(),
            "video-on-demand-metrics": _ctx.localProgramItem.VodDetail.VodVideoAnalytics,
            "stream-type": _ctx.localProgramItem.VodDetail.VodStreamingType,
            "module-type": _ctx.ProgramModule
          }, null, 8, ["vonage-analytics-for-graph", "selected-program-id", "video-detail", "video-on-demand-metrics", "stream-type", "module-type"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ShowCheckedInParticipantDetails())
      ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
          _createVNode(_component_CheckedInParticipantDetails, {
            "checked-in-participant-data": _ctx.checkedInParticipantDetails,
            onShowCheckedInParticipantDetails: _cache[28] || (_cache[28] = ($event: any) => (_ctx.GetCheckedInParticipantData($event)))
          }, null, 8, ["checked-in-participant-data"])
        ]))
      : _createCommentVNode("", true)
  ]))
}