import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "batch-operation" }
const _hoisted_2 = {
  key: 1,
  class: "right-container-margin"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = {
  key: 5,
  class: "right-container-margin"
}
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { class: "main-heading-parent" }
const _hoisted_9 = { class: "bulk-action-text-margin" }
const _hoisted_10 = { class: "batch-operation-main-content" }
const _hoisted_11 = { class: "action-middle-div" }
const _hoisted_12 = ["index"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "img-select iconSize" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "valign batch-content-width" }
const _hoisted_17 = ["id"]
const _hoisted_18 = { class: "description" }
const _hoisted_19 = {
  key: 0,
  class: "action-middle-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_AddInformationWithEntity = _resolveComponent("AddInformationWithEntity")!
  const _component_ProgramEditTags = _resolveComponent("ProgramEditTags")!
  const _component_ProgramAssignTracks = _resolveComponent("ProgramAssignTracks")!
  const _component_ProgramBatchDateTime = _resolveComponent("ProgramBatchDateTime")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Program.DeletePrograms'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.showAddInformationBatchPane)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AddInformationWithEntity, {
            "all-informations": _ctx.localAllInformations,
            "selected-items": _ctx.selectedItemsIds,
            "custom-category-folders": _ctx.localCustomCategoryFolders,
            "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
            "is-bulk-operation": true,
            "entity-type": _ctx.entityType,
            onSelectBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAttachInformation()))
          }, null, 8, ["all-informations", "selected-items", "custom-category-folders", "selected-bulk-operation-model", "entity-type"])
        ]))
      : (_ctx.showEditTagsBatchPane)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ProgramEditTags, {
              "available-tags": _ctx.availableTags,
              "selected-items": _ctx.selectedItems,
              "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
              onSelectBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ShowEditTagsBatchPane($event)))
            }, null, 8, ["available-tags", "selected-items", "selected-bulk-operation-model"])
          ]))
        : (_ctx.showAssignToTracksBatchPane)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_ProgramAssignTracks, {
                "track-items": _ctx.trackItems,
                "selected-items": _ctx.selectedItems,
                "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
                onSelectBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowAssignToTracksBatchPane(false)))
              }, null, 8, ["track-items", "selected-items", "selected-bulk-operation-model"])
            ]))
          : (_ctx.showProgramBatchDateTimePane)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ProgramBatchDateTime, {
                  "selected-items": _ctx.selectedItems,
                  "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
                  onSelectBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowBatchDateTimePane(false)))
                }, null, 8, ["selected-items", "selected-bulk-operation-model"])
              ]))
            : (_ctx.showProgramBatchImagesPane)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_ImageProcess, {
                    "featured-image-full-src": _ctx.featureMissingImage,
                    "featuredimage-src": _ctx.featureMissingImage,
                    "heading-title": _ctx.isEnableFeaturedImage== true ? _ctx.$t('ImageProcess.BatchHeadingTitle') : _ctx.$t('ImageProcess.BatchHeadingIconOnlyTitle'),
                    "image-src": _ctx.missingImage,
                    "image-full-src": _ctx.missingImage,
                    "module-name": _ctx.ProgramModule,
                    "selected-items": _ctx.selectedItems,
                    "is-bulk-operation": true,
                    "selected-bulk-operation-model": _ctx.localSelectedBulkOperationModel,
                    "show-featured-image": _ctx.isEnableFeaturedImage,
                    onSelectBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowBatchImagesPane(false))),
                    onUpdateBulkImages: _cache[6] || (_cache[6] = ($event: any) => (_ctx.UpdateBulkImages($event)))
                  }, null, 8, ["featured-image-full-src", "featuredimage-src", "heading-title", "image-src", "image-full-src", "module-name", "selected-items", "selected-bulk-operation-model", "show-featured-image"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("Program.Batch.Header1")) + " " + _toDisplayString(_ctx.selectedItems.length) + " " + _toDisplayString(_ctx.$t("Program.Batch.Header2")), 1)
                  ]),
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("Bulk.SelectAction")), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "action-side-width" }, null, -1)),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bulkEditOperations.arrBulkOperations, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.Id,
                          index: index,
                          class: _normalizeClass(["inner-content", _ctx.evenIndex(index)==true?'action-content-width-left':'action-content-width-right'])
                        }, [
                          _createElementVNode("div", {
                            class: "inner-content",
                            onClick: ($event: any) => (_ctx.handle_function_call(item.objModel,item.ConfirmationMessage,item.ShowConfirmationPopup))
                          }, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("img", {
                                src: item.IconUrl,
                                style: _normalizeStyle(item.IconPadding)
                              }, null, 12, _hoisted_15)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["selectInformationStyle valign-parent60", item.Name=='Edit Icons'?'':'item-position-from-image'])
                            }, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("label", {
                                  id: 'programBulkEditOperations'+index,
                                  class: "hyperLink questionLinkFont"
                                }, _toDisplayString(item.Name), 9, _hoisted_17),
                                _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("span", _hoisted_18, _toDisplayString(item.Description), 1)
                              ])
                            ], 2)
                          ], 8, _hoisted_13),
                          (_ctx.evenIndex(index))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_12))
                      }), 128))
                    ]),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "action-side-width" }, null, -1))
                  ])
                ]))
  ]))
}