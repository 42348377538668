import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "margin-top-15" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  id: "dvMeetAppLiveThresholdLimitReachedDetails",
  class: "alert alert-warning thresh-holdLimit-warning inline-flex100",
  role: "alert"
}
const _hoisted_5 = { class: "threshold-message message-content" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "virtual-meeting-vonage-section border-radius-10"
}
const _hoisted_8 = { class: "bold-text" }
const _hoisted_9 = { class: "margin-top-5" }
const _hoisted_10 = { class: "description" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  id: "dvMeetAppLiveRecording"
}
const _hoisted_13 = { class: "description" }
const _hoisted_14 = { class: "description" }
const _hoisted_15 = { class: "dvMeetAppLiveInformationIcon" }
const _hoisted_16 = {
  key: 0,
  class: "dvMeetAppLiveRecordingOption"
}
const _hoisted_17 = { class: "description" }
const _hoisted_18 = ["checked"]
const _hoisted_19 = { class: "description" }
const _hoisted_20 = ["checked"]
const _hoisted_21 = { class: "bold-text" }
const _hoisted_22 = { class: "vonage-link-parent" }
const _hoisted_23 = { class: "vonage-link-text" }
const _hoisted_24 = { class: "makeTextItalic" }
const _hoisted_25 = { class: "top-margin-in-fields" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "clsMarginTop10px" }
const _hoisted_28 = { class: "bold-text" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { key: 2 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 3 }
const _hoisted_36 = { class: "meeting-invalid-div border-radius-10" }
const _hoisted_37 = { class: "meeting-passed-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_MeetappPricingDetails = _resolveComponent("MeetappPricingDetails")!
  const _component_MeetAppLiveConsolidatedDetails = _resolveComponent("MeetAppLiveConsolidatedDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_loading, {
        "show-loading": _ctx.showLoading,
        "border-color": _ctx.loaderBorderColor
      }, null, 8, ["show-loading", "border-color"])
    ]),
    _createElementVNode("div", null, [
      (_ctx.showMeetappPricingDetailsPopup)
        ? (_openBlock(), _createBlock(_component_MeetappPricingDetails, {
            key: 0,
            "show-pricing-popup": _ctx.showMeetappPricingDetailsPopup,
            "product-pricing-information": _ctx.meetAppLivePricingInformation,
            "pricing-popup-type": _ctx.pricingPopupType
          }, null, 8, ["show-pricing-popup", "product-pricing-information", "pricing-popup-type"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showCopiedMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[12] || (_cache[12] = _createElementVNode("i", {
                class: "fa fa-check",
                "aria-hidden": "true"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.vonageThresholdLimitReached)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[13] || (_cache[13] = _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-icon" }, [
                _createElementVNode("i", {
                  class: "fa fa-exclamation-circle",
                  "aria-hidden": "true"
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLive.TresholdLimitReached')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showVonageAnalyticsDiv && _ctx.isMeetAppLiveMeetingValid())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (!_ctx.vonageThresholdLimitReached)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLiveLine1')), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLiveLine2')), 1),
                  _createElementVNode("span", {
                    class: "hyperLink",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowMeetAppLivePricingPopUp('MeetAppPricing')))
                  }, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLiveLine2PricingLink')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLiveLineForMoreInfo')), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      ref: "getVonageLiveVideoLinkCheckbox",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isVonageLinkGenerated) = $event)),
                      type: "checkbox",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetLiveVideoLink()))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.isVonageLinkGenerated]
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.CheckBox")), 1)
                  ])
                ]),
                (_ctx.showVonageStudioLink)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (_ctx.isEnableMeetAppLiveRecording())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("div", null, [
                              _createElementVNode("label", _hoisted_13, [
                                _withDirectives(_createElementVNode("input", {
                                  ref: "getVonageLiveVideoRecordingCheckbox",
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.IsRecordedSession) = $event)),
                                  type: "checkbox",
                                  class: "vonage-live-video-recording-checkbox",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.GetRecordingOption(), _ctx.MakeRecordingAvailable()))
                                }, null, 512), [
                                  [_vModelCheckbox, _ctx.IsRecordedSession]
                                ]),
                                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.RecordingCheckBox")), 1)
                              ]),
                              _createElementVNode("span", null, " (" + _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.RecordingAdditional")), 1),
                              _createElementVNode("span", null, [
                                _createElementVNode("span", {
                                  class: "pricingDetailMeetAppLiveRecording cursor-pointer",
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowMeetAppLivePricingPopUp('MeetAppLiveRecordingPricing')))
                                }, _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.RecordingPricing")), 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.RecordingWillApply")) + ") ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("i", {
                                  class: "fa fa-info-circle",
                                  "aria-hidden": "true",
                                  onMouseover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.getInformationString(true))),
                                  onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getInformationString(false)))
                                }, null, 32),
                                _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.RecordingInformation")), 513), [
                                  [_vShow, _ctx.isVideoUnavailableInfo]
                                ])
                              ])
                            ]),
                            (_ctx.showVonageRecordingOption)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("label", _hoisted_17, [
                                      _createElementVNode("input", {
                                        type: "radio",
                                        name: "MeetAppLiveRecordingType",
                                        value: "automatically",
                                        checked: _ctx.isAutomatically,
                                        onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.MakeRecordingAutomatically(true)))
                                      }, null, 40, _hoisted_18),
                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.StartRecordingAutomaticallyCheckbox")), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("label", _hoisted_19, [
                                      _createElementVNode("input", {
                                        type: "radio",
                                        name: "MeetAppLiveRecordingType",
                                        value: "manually",
                                        checked: !_ctx.isAutomatically,
                                        onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.MakeRecordingAutomatically(false)))
                                      }, null, 40, _hoisted_20),
                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("VirtualMeetings.MeetAppLive.StartRecordingManuallyCheckBox" )), 1)
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", null, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('VirtualMeetings.YourStudioLink')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.selectedItem.VirtualMeetingLink), 1)
                        ]),
                        (_ctx.showCopyToClipboard)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "vonage-copy-icon",
                              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.copyPublicLinkToClipboard && _ctx.copyPublicLinkToClipboard(...args)))
                            }, _cache[14] || (_cache[14] = [
                              _createElementVNode("i", {
                                class: "fa fa-link",
                                "aria-hidden": "true"
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("button", {
                          class: "btn btn-primary btn-size-default",
                          disabled: !_ctx.showCopyToClipboard,
                          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.OpenVonageLiveStream()))
                        }, _toDisplayString(_ctx.$t("VirtualMeetings.Button.OpenStreamingStudio")), 9, _hoisted_26)
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('VirtualMeetings.Notes')), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", null, [
                            (_ctx.moduleType === 'Program')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.$t('VirtualMeetings.NotesLine1')), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.$t('VirtualMeetings.EventNotesLine1')), 1))
                          ]),
                          _createElementVNode("li", null, [
                            (_ctx.moduleType === 'Program')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t('VirtualMeetings.NotesLine2')), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t('VirtualMeetings.EventNotesLine2')), 1))
                          ]),
                          _createElementVNode("li", null, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('VirtualMeetings.NotesLine3')), 1)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showVonageAnalyticsDiv && _ctx.isMeetAppLiveMeetingValid())
      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
          (_ctx.vonageAnalytics != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createVNode(_component_MeetAppLiveConsolidatedDetails, {
                  "meet-app-live-analyticsdata": _ctx.vonageAnalytics,
                  "selected-programs-details": _ctx.selectedItem
                }, null, 8, ["meet-app-live-analyticsdata", "selected-programs-details"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showVonageAnalyticsDiv && !_ctx.isMeetAppLiveMeetingValid())
      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('VirtualMeetings.MeetAppLive.MeetingPassed')), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}