import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-margin" }
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = {
  key: 0,
  class: "padding-top-20"
}
const _hoisted_10 = { class: "margin-top-30" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "margin-top-30" }
const _hoisted_13 = { class: "inline-flex100" }
const _hoisted_14 = { class: "width33 inline-flex align-center" }
const _hoisted_15 = { class: "line-height" }
const _hoisted_16 = { class: "width33 inline-flex justify-center align-center" }
const _hoisted_17 = { class: "line-height" }
const _hoisted_18 = { class: "width33 inline-flex justify-flex-end align-center" }
const _hoisted_19 = { class: "line-height" }
const _hoisted_20 = { class: "preview-link" }
const _hoisted_21 = ["index"]
const _hoisted_22 = { class: "inline-flex100" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "program-title-parent" }
const _hoisted_25 = { class: "time" }
const _hoisted_26 = { class: "inline-flex100" }
const _hoisted_27 = { class: "description-heading" }
const _hoisted_28 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('EditDateTime'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Cancel()))
            }, _toDisplayString(_ctx.$t("CancelButton")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("EditDateTime")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Save()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ]),
        (_ctx.visibleValidationSummary)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_ValidationSummary, {
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", {
            innerHTML: _ctx.$t('EditDateTime.Description')
          }, null, 8, _hoisted_11)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.days) = $event)),
                type: "number",
                class: "form-control",
                onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.OnDaysChange && _ctx.OnDaysChange(...args)))
              }, null, 544), [
                [_vModelText, _ctx.days]
              ]),
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('Days')), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.hours) = $event)),
                type: "number",
                class: "form-control",
                onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.OnHoursChange && _ctx.OnHoursChange(...args)))
              }, null, 544), [
                [_vModelText, _ctx.hours]
              ]),
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('Hours')), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.minutes) = $event)),
                type: "number",
                class: "form-control",
                onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.OnMinutesChange && _ctx.OnMinutesChange(...args)))
              }, null, 544), [
                [_vModelText, _ctx.minutes]
              ]),
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('Minutes')), 1)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('Edit.Preview')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedSelectedProgramItems, (programItem, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: programItem.Id,
                index: index,
                class: "preview-section inline-flex100"
              }, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("img", {
                    src: programItem.ImageUrl,
                    class: "program-icon-Img"
                  }, null, 8, _hoisted_23),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.FormatDateTime(programItem)), 1),
                    _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, _toDisplayString(programItem.Title), 1)
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1))
                  ])
                ])
              ], 8, _hoisted_21))
            }), 128)),
            (_ctx.moreItemsCount > 0)
              ? (_openBlock(), _createElementBlock("label", _hoisted_28, _toDisplayString(_ctx.MoreItemsString()), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}